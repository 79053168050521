﻿import {Floor} from './Floor'
import {DescriptionUnit} from './DescriptionUnit'
import {SpaceUnit} from './SpaceUnit'
import {VolumeUnit} from './VolumeUnit'

export function Project() {

	this.id = "";
	this.name = "";
	this.notes = "";
	this.createdAt = new Date();
	this.updatedAt = new Date();

	this.$hasUnsyncedChanges = false;
	this.$isDeleted = false;

	this.localInspection = {
		appointments: "",
		participants: "",
		dataBasis: "",
		permissionToTakePictures: "",
		rightsBasedOnCadastre: ""
	};

	this.buildingDetails = {
		typeOfBuilding: "",
		inheritedWaste: "",
		buildYear: "",
		modernization: "",
		energyEfficiency: "",
		externalView: "",
		plinth: "",
		optionsOfExtensions: ""
	};

	this.floors = [];
	
	this.buildingConstruction = {
		foundation: '',
		typeOfConstruction: '',
		insideWalls: '',
		outerWalls: '',
		staircases: {
			generalImpression: '',
			storeyStaircases: ''
		},
		doorOfHouseEntrance: '',
		doorOfSideEntrance: '',
		roofConstruction: {
			possibilityToExamine: '',
			construction: '',
			shape: '',
			covering: '',
			insulationTopmostCeiling: '',
			insulationBetweenRafters: '',
			insulationBetweenRaftersLivingArea: '',
			damage: ''
		}
	};
	
	this.generalTechnicalEquipment = {
		heating: '',
		hotWaterInstallations: '',
		sewageInstallations: '',
		electricalInstallation: '',
		separatedCounters: '',
		ventilation: '',
		waterInstallations: ''	
	};
	
	this.descriptionAndPartition = {
		specialFacility: '',
		units: []	
	};
	
	this.spaceCalculation = {
		calculationBase: '',
		spaceUnits: []
	};

	this.volumeCalculation = {
		calculationBase: '',
		volumeUnits: []
	};

	this.deprecation = {
		structuralDamageAndConstructionDefects: '',
		groundplan: '',
		economicalDeprecation: '',
		specialBuildingComponents: '',
		tanningAndExposureToLight: '',
		generalAssessment: ''
	};
	
	this.additionalBuildings = '';

	this.outdoorFacility = '';
	
	this.countFloorsOfType = function(typeId) {
		var counter = 0;
		for (var i in this.buildingConstruction.floors)	 {
			if (this.buildingConstruction.floors[i].typeId == typeId) {
				counter++;
			}
		}
		return counter;
	};
	
	this.createFloorLabel = function(floorTypeRef) {
		var s = floorTypeRef.label;
		if (this.countFloorsOfType(floorTypeRef.id) > 0) {
			var num = 2;
			checkLoop: while (true) {
				var tmpS = s + " " + num;
				for (var floorIndex in self.buildingConstruction.floors) {
					if (tmpS ==  self.buildingConstruction.floors[floorIndex].label)
						continue checkLoop;
				}
				s = tmps;
				break;
			}
		}
		return s;	
	};
	
	this.getFloorById = function (floorId) {
		for (var i in this.floors) {
			if (this.floors[i].id == floorId) {
				return this.floors[i];
			}
		}
		return null;
	};

	this.deleteDescriptionUnit = function (descriptionUnit) {
		var index = this.descriptionAndPartition.units.indexOf(descriptionUnit);

		if (index > -1)
			this.descriptionAndPartition.units.splice(index, 1);
	};

	this.deleteFloor = function (floor) {
		var index = this.floors.indexOf(floor);

		if (index > -1)
			this.floors.splice(index, 1);
	};

	this.getSpaceUnitById = function (spaceUnitId) {
		for (var index in this.spaceCalculation.spaceUnits) {
			if (this.spaceCalculation.spaceUnits[index].id == spaceUnitId)
				return this.spaceCalculation.spaceUnits[index];
		}
		return null;
	};

	this.getVolumeUnitById = function (volumeUnitId) {
		for (var index in this.volumeCalculation.volumeUnits)
		{
			if (this.volumeCalculation.volumeUnits[index].id == volumeUnitId)
				return this.volumeCalculation.volumeUnits[index];
		}
	};

	this.deleteVolumeUnit = function (unit) {
		var index = this.volumeCalculation.volumeUnits.indexOf(unit);
		if (index > -1)
			this.volumeCalculation.volumeUnits.splice(index, 1);
	};

	this.fullVolume = function () {
		var self = this;
		var vol = 0;
		for (var index in self.volumeCalculation.volumeUnits) {
			for (var index2 in self.volumeCalculation.volumeUnits[index].volumes) {
				console.log(self.volumeCalculation.volumeUnits[index].volumes);
				vol += self.volumeCalculation.volumeUnits[index].volumes[index2].calculate();
			}
		}
		return vol;
	};
}	

Project.getTyping = function () {
    return {
        createdAt: {
            _type: Date
        },

        updatedAt: {
            _type: Date
        },


        floors: {
            _isArray: true,
            _type: Floor
        },

        descriptionAndPartition: {
            units: {
                _isArray: true,
                _type: DescriptionUnit
            }
        },

        spaceCalculation: {
            spaceUnits: {
                _isArray: true,
                _type: SpaceUnit
            }
        },

        volumeCalculation: {
            volumeUnits: {
                _type: VolumeUnit,
                _isArray: true
            }
        }
    };
};