﻿angular.module("controllers").controller("startupController", function ($scope, $state, userService, $controller, $window, projectRepository, projectService, ModalService, $rootScope) {
	$scope.username = '';
	$scope.password = '';
	$scope.hasErrors = false;

	$scope.$on("$viewContentLoaded", function() {
		console.log("here we go", $rootScope.apiToken);
		if ($rootScope.apiToken)
			$state.go('projects-list');
	});

	$scope.loginAsync = async function() {
		if (await userService.loginAsync($scope.username, $scope.password))
		{
			console.log("going");
			$state.go('projects-list');
		}
		else {
			$scope.hasErrors = true;
		}
	};
});