import {Space} from '../models/Space'
import {Room} from '../models/Room'

angular.module("controllers").controller("roomController", function ($scope, $controller, project, itemDataService, ngDialog, room, descriptionUnit, $state) {
	$controller("descriptionUnitBaseController", { $scope: $scope, project: project, itemDataService: itemDataService, descriptionUnit: descriptionUnit });

	$scope.room = room;
	$scope.newSpace = new Space();
	$scope.defaultPlasterDeduction = Space.defaultPlasterDeduction;

	$scope.orientations = Room.Orientations;

	$scope.addSpace = function () {
		$scope.room.spaces.push($scope.newSpace);
		$scope.newSpace = new Space();
	};

	$scope.deleteSpace = function (space) {
		$scope.room.deleteSpace(space);
	};

	$scope.deleteRoom = function () {
		$scope.descriptionUnit.deleteRoom($scope.room);
		$state.go('project-building-construction', { projectId: $scope.project.id });
	};
});