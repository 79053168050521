﻿angular.module("directives").directive("textBuilder", function ($timeout, $rootScope, ModalService) {
	return {
		restrict: 'AE',

		scope: {
			text: '=',
			items: '=items',
			alwaysActive: '=?',
			singleLine: '=?'
		},

		link: function (scope, element) {
			scope.active = false;
			scope.text = scope.text || "";
			scope.alwaysActive = scope.alwaysActive || false;
			scope.singleLine = scope.singleLine || false;
			scope.defaultItems = [
				{ text: ',', separator: '' },
				{ text: '.', separator: '' }
			];
			scope.history = [];

			if (scope.alwaysActive)
				scope.active = true;
			
			scope.appendItem = function (item) {
				// check block contains placeholders and in case open fill dialog
				var regexp = /\$\{.*?\}/g;
				if (regexp.test(item.text)) {
					return ModalService.showModal({
						template: require('../views/textBlockFillPlaceholdersDialog.html'),
						controller: 'textBlockFillPlaceholdersDialogController',
						inputs: {
							textBlock: item.text
						},
						closeDelay:500, 
						preClose: (modal) => { modal.element.modal('hide'); }
					}).then(function (modal) {
						modal.element.modal();
						
						return modal.close;
						
					}).then(function (res) {
							
						if (typeof (res) === 'string' && res.length > 0) {
							var newItem = angular.copy(item);
							newItem.text = res;
							scope.appendItem(newItem);
						}
						return;
					});
					return;
				}

				// save history
				scope.history.push(scope.text);

				// append block
				if (typeof (scope.text) == "string" && scope.text.length > 0)
					scope.text += (typeof item.separator !== 'undefined') ? item.separator : " ";
				
				scope.text = scope.text + item.text;
				element.find("textarea").triggerHandler("change");
			}

			scope.$on("textBuilderActivated", function (event) {
				if (! scope.alwaysActive)
					scope.active = false;
			});

			scope.activate = function () {
				$rootScope.$broadcast("textBuilderActivated");
				scope.active = true;


			};

			scope.undo = function () {
				if (scope.history.length > 0)
				{
					var t = scope.history.pop();
					scope.text = t;
				}
			};

		},

		template: '' +
			'<div class="text-builder" ng-class="{active: active}">' +
			'<div class="output-area" ng-hide="active"><p>{{text || "kein Inhalt"}}</p><button class="btn btn-default" ng-click="activate()">Bearbeiten</button></div>' +
			'<div class="output-area" ng-show="active"><input ng-if="singleLine" type="text" ng-model="$parent.text" class="form-control" /><textarea ng-if="!singleLine" ng-model="$parent.text" elastic rows="2" class="form-control"></textarea></div>' +
			'<div class="block-area" ng-show="active"><span class="item" ng-click="undo()" ng-disabled="!history.length"><i class="fa fa-undo"></i> Rückgängig</span><span ng-repeat="item in defaultItems" ng-click="appendItem(item)" class="item">{{item.text}}</span><br/><span ng-repeat="item in items" ng-click="appendItem(item)" class="item">{{item.text}}</span></div></div>'
	};
});