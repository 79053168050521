﻿// import 'projectList.html'

angular.module("controllers").controller("projectsListController", function ($scope, projectService, $state, Notification, $q, ModalService, projectRepository, projects, userService) {
	$scope.projects = projects;
	$scope.isLoadingProjects = false;
	$scope.isSyncing = false;
	$scope.sortProjectsBy = '-updatedAt';
	$scope.isMultiDeleteEnabled = false;
	$scope.selectedProjects = [];


	// function loadProjects() {
	// 	$scope.isLoadingProjects = true;

	// 	return projectService.getAll()
	// 	.then(function (allProjects) {
	// 		if (Array.isArray(allProjects))
	// 			$scope.projects = allProjects;
	// 		$scope.isLoadingProjects = false;
	// 		//Notification.success('Projekte geladen');
	// 	});
	// }

	$scope.doMultiDelete = async function() {
		if (!$scope.isMultiDeleteEnabled || ! $scope.selectedProjects)
			return;
		
		const confirmation = confirm(`Sind Sie sicher, dass Sie ${$scope.selectedProjects.length} Projekte löschen möchten?`);
		if (! confirmation)
			return;

		$scope.isLoadingProjects = true;

		// let promises = [];
		for (var p of $scope.selectedProjects) {
			await projectService.deleteAsync(p);
		}

		// await $q.all(promises)
		await $scope.refreshAsync();
		$scope.isLoadingProjects = false;
		$scope.isMultiDeleteEnabled = false;
		$scope.selectedProjects.splice(0);
		console.log("isLoading", $scope.isLoadingProjects);
		$scope.$applyAsync();
	}

	$scope.toggleMultiDelete = function() {
		if ($scope.isMultiDeleteEnabled) {
			$scope.selectedProjects.splice(0);
			$scope.isMultiDeleteEnabled = false;
		}
		else {
			$scope.selectedProjects.splice(0);
			$scope.isMultiDeleteEnabled = true;
		}
	}

	$scope.refreshAsync = async function() {
		// let projects = await projectService.getAllAsync(true);
		// $scope.projects.length = 0;
		// for (let p of projects) {
		// 	$scope.projects.push(p)
		// }
		$scope.isLoadingProjects = true;
		await projectService.syncAsync();
		const projects = await projectService.getAllAsync();
		if (projects) {
			$scope.projects = projects;
		}
		$scope.isLoadingProjects = false;
		$scope.$applyAsync();
	}

	$scope.isNotDeleted = function(project) {

		return (! project.$isDeleted);
	};

	$scope.createProject = function () {
		projectService.createNew()
			.then(function (project) {
				$scope.projects.push(project);
				return project;
			})
			.then(function (project) {
				$state.go('project-main', {projectId: project.id})
			});

	};

	$scope.hasUnsyncedChanges = function () {
		for (var index in $scope.projects) {
			
			if ($scope.projects[index].$hasUnsyncedChanges) {
				return true;
			}
		}

		return false;
	};
	

	$scope.logoutAsync = async function() {
		console.log("Logging out");
		if (!confirm("Sind Sie sicher, dass Sie sich auloggen möchten? Ungespeicherte Daten gehen verloren."))
			return;

		await projectService.logoutAsync();
		await userService.logoutAsync();
		$state.go('startup');
	};

	$scope.syncUnsyncedChanges = async function () {
		let projects = await projectService.syncAsync();
		$scope.projects = projects;
		$scope.$applyAsync();

		// ModalService.showModal({
		// 	template: require('./syncChangesDialog.html'),
		// 	controller: 'syncChangesDialogController',
		// 	inputs: {
		// 		$q: $q,
		// 		projectRepository: projectRepository
		// 	}
		// })
		// .then(function (modal) {
		//     console.log(modal);
		// 	modal.element.modal();
		// 	return modal.close;
		// })
		// .then(function () {
		// 	return loadProjects();
		// });
	};

	$scope.selectProject = function(project) {
		if ($scope.isMultiDeleteEnabled) {
			if ($scope.isSelectedProject(project)) {
				$scope.selectedProjects.splice($scope.selectedProjects.indexOf(project), 1);
			}
			else {
				$scope.selectedProjects.push(project);
			}	
		}
		else
			$state.go('project-main', { projectId: project.id });
	};

	$scope.isSelectedProject = function(project) {
		for (let p of $scope.selectedProjects) {
			if (p.id == project.id)
				return true;
		}

		return false;
	}
	//Notification.info('Lade Projekte...');

	// $scope.onInit = function() {
	// 	console.log("init");
	// 	loadProjects();
	// };
	
});