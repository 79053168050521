﻿angular.module("services").factory("userService", function ($http, $rootScope, $httpParamSerializer, $localForage) {
	const root = 
	{
		token: '',

		loginAsync: async function(username, password) {
			return await root.login(username, password);
		},

		logoutAsync: async function() {
			$rootScope.apiToken = null;
			await $localForage.clear();
		},

		login: function (username, password) {
			//return $http.post(CONFIG.backendUrl + '/api/users/login', { Username: username, Password: password })
			// return $http.post(CONFIG.backendUrl + '/connect/token', )
			return $http({
				headers: {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'},
				url: CONFIG.backendUrl + '/connect/token',
				method: 'POST',
    			transformRequest: $httpParamSerializer,
				data: {'client_id': 'spa', 'client_secret': 'mySecret', 'grant_type': 'password', 'scope': 'api1', 'username': username, 'password': password}
			})
			.then(function(response) {
				$rootScope.apiToken = response.data.access_token;
				$localForage.setItem('apiKey', response.data.access_token);
				return response.data;
			}, function() {
				return false;
			});
			// .then(function() {
			// 	return $http.post(CONFIG.backendUrl + '/connect/token', {'client_id': 'spa', 'client_secret': 'mySecret', 'grant_type': 'authorization_code', 'username': username, 'password': password});
			// })
			// .then(function(result) {
			// 	console.log(result);
			// });
		},

		restoreSession: function() {
			return $localForage.getItem('apiKey').then((value) => {
				if (value) {
					console.log("restoring", value);
					$rootScope.apiToken = value;
				}
			})
		}
	};

	return root;
});