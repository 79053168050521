﻿import {SpaceDto} from './SpaceDto'

export function RoomDto() {
	this.Id = '';
	this.FloorId = '';
	this.Name = '';
	this.DescriptionUnitId = '';
	this.Orientation = '';
	this.Spaces = [];
	this.Sanitation = '';
	this.KitchenEquipment = '';
	this.Floorings = '';
	this.Panelling = '';
	this.Ceiling = '';
	this.Windows = '';
	this.Deprecation = {
		StructuralDamageAndConstructionDefects: '',
		EconomicalDeprecation: '',
		TanningAndExposureToLight: '',
		GeneralAssessment: ''
	};

}

RoomDto.getTyping = function() { return {
        Spaces: {
            _isArray: true,
            _type: SpaceDto
        }
}};