﻿angular.module("controllers").controller("sendProjectByMailDialogController", function ($scope, projectService, project, close) {
	$scope.recipientEMail = '';
	$scope.project = project;
	$scope.isSending = false;

	$scope.send = function () {
		$scope.isSending = true;
		projectService.requestProjectByMail($scope.project, $scope.recipientEMail)
			.then(function (result) {
				close(true);
			});
	};

	$scope.cancel = close;
	
});