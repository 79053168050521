﻿import {Floor} from '../models/Floor'

angular.module("controllers").controller("chooseFloorTypeDialogController", function ($scope, close) {
	$scope.closeThisDialog = close;

	$scope.types = Floor.Types;
	

	$scope.done = function (typeId) {
		$scope.closeThisDialog(typeId);
	};

	$scope.cancel = function () {
		$scope.closeThisDialog(false);
	};

	
});