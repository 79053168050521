export function FloorDto() {
	this.Id = 0;
	this.Name = '';
	this.TypeId = 0;
	this.StoreyCeiling = '';
	this.TypeOfConstruction = '';
	this.Damage = '';
	this.Staircase = '';
	this.ExteriorStaircase = '';
	this.Fitout = {
		HasFitout: false,
		Floor: '',
		Walls: '',
		Ceiling: '',
		Windows: '',
		Doors: ''	
	};
	this.AtticStaircase = '';
	
}