﻿angular.module("controllers").controller("inputDescriptionUnitNameDialogController", function ($scope, itemDataService, close) {
	
	$scope.closeThisDialog = close;

	$scope.name = "";
	
	$scope.itemsFor = function (path) {
		return itemDataService.getFor(path);
	};

	$scope.done = function () {
		$scope.closeThisDialog($scope.name);
	};

	$scope.cancel = function () {
		$scope.closeThisDialog(false);
	};

	
});