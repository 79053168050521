﻿angular.module("controllers").controller("syncChangesDialogController", function ($scope, projectRepository, $q, close) {
	
	$scope.cancel = function () {
		close(false);
	};

	projectRepository.syncAsync()
		
		.then(function () {
			close(true);
		});
});