﻿angular.module("controllers").controller("textBlockFillPlaceholdersDialogController", function ($scope, textBlock, close) {
	$scope.closeThisDialog = close;
	
	$scope.textBlock = textBlock;


	$scope.placeholders = [];
	$scope.placeholderValues = [];

	$scope.processedTextBlock = function () {
		var result = $scope.textBlock;
		angular.forEach($scope.placeholders, function (placeholder) {
			var regexp = new RegExp('\\$\\{' + placeholder + '\\}', 'g');
			result = result.replace(regexp, $scope.placeholderValues[placeholder] || "");
		});
			
		return result;
	};

	$scope.done = function () {
		$scope.closeThisDialog($scope.processedTextBlock(), 200);
	};

	$scope.cancel = function () {
		$scope.closeThisDialog("");
	};

	$scope.trueOrUndefined = function (val) {
		if (val)
			return true;
		return undefined;
	};

	var regexp = /\$\{(.*?)\}/g;
	
	var item = null;
	while (item = regexp.exec($scope.textBlock)) {
		
		if (typeof (item) === 'object') {

			if (typeof(item[1]) === 'string') {
				$scope.placeholders.push(item[1]);
			}
		}
	}
});