﻿import {DescriptionUnit} from '../models/DescriptionUnit'
import {DescriptionUnitDto} from '../models/DescriptionUnitDto'
import {Floor} from '../models/Floor'
import {FloorDto} from '../models/FloorDto'
import {Project} from '../models/Project';
import {ProjectDto} from  '../models/ProjectDto'
import {Room} from '../models/Room'
import {RoomDto} from '../models/RoomDto'
import {Space} from '../models/Space'
import {SpaceDto} from '../models/SpaceDto'
import {SpaceUnit} from '../models/SpaceUnit'
import {SpaceUnitDto} from '../models/SpaceUnitDto'
import {Volume} from '../models/Volume'
import {VolumeDto} from '../models/VolumeDto'
import {VolumeUnit} from '../models/VolumeUnit'
import {VolumeUnitDto} from '../models/VolumeUnitDto'

angular.module("services").factory("projectService", function (uuid, projectRepository, $http, mapperService) {
	var root = 
	{
		logoutAsync: async function() {
			return await projectRepository.logoutAsync();
		},
		
		getAllAsync: async function(includeDeleted = false) {
			return await projectRepository.getAllAsync(includeDeleted);
		},

		getByIdAsync: async function(id) {
			return await projectRepository.getByIdAsync(id);
		},

		saveAsync: async function(project) {
			await projectRepository.saveAsync(project);
		},

		syncAsync: async function() {
			return await projectRepository.syncAsync();
		},

		deleteAsync: async function(project) {
			return await projectRepository.deleteAsync(project);
		},

		getById: function (projectId) {
			return projectRepository.getById(projectId);
		},

		getAll: function () {
			return projectRepository.getAll();
		},

		save: function (project) {
			if (Date.now() - project.updatedAt.getTime() > 10000)
				project.updatedAt = new Date();
			return projectRepository.save(project);
		},

		requestProjectByMail: function(project, recipientEMail) {
		    return $http.post(CONFIG.backendUrl + "/api/projects/" + project.id + "/sendByMail", { 'recipientEMail': recipientEMail })
				.then(function (response) {
					return response.data;
				}, function () {
					return false;
				});
		},

		createNew: function () {
			var newProject = new Project();
			newProject.name = "Neues Projekt";
			newProject.id = uuid.v4();
			return projectRepository.save(newProject);
		},

		delete: function(project) {
			return projectRepository.delete(project);
		},

		createNewFloor: function (floorTypeId, project) {
			var newFloor = new Floor();
			newFloor.name = "Geschoss";
			newFloor.typeId = floorTypeId;
			var floorType = null;

			for (var index in Floor.Types) {
				if (Floor.Types[index].id == floorTypeId) {
					newFloor.name = Floor.Types[index].name;
					floorType = Floor.Types[index];
				}
			}

			// autoinc name
			var counter = 0;
			for (var index in project.floors) {
				if (project.floors[index].name.indexOf(newFloor.name) > -1)
					counter++;
			}
			if (counter > 0)
				newFloor.name = (counter + 1) + ". " + newFloor.name;
			else if (floorType != null && floorType.allowMultiple)
				newFloor.name = "1. " + newFloor.name;
				
			

			newFloor.typeId = floorTypeId;
			newFloor.id = uuid.v4();
			return newFloor;
		},

		createNewRoom: function (descriptionUnit, roomName, roomTypeId) {
			var newRoom = new Room();
			newRoom.id = uuid.v4();
			newRoom.name = roomName;
			newRoom.descriptionUnitId = descriptionUnit.id;
			//newRoom.floorId = floor.id;
			//newRoom.typeId = roomTypeId;
			
			// auto inc name
			if (descriptionUnit && Array.isArray(descriptionUnit.rooms))
			{
				var counter = 0;
				descriptionUnit.rooms.forEach(function (room) {
					if (room.name == newRoom.name)
						counter++;
				});

				if (counter > 0)
					newRoom.name += " " + (counter + 1);
			}

			return newRoom;
		},

		createNewDescriptionUnit: function () {
			var newDescriptionUnit = new DescriptionUnit();
			newDescriptionUnit.id = uuid.v4();
			return newDescriptionUnit;
		},

		duplicateDescriptionUnit: function(descriptionUnit) {
			let copy = root.createNewDescriptionUnit();
			const copyId = copy.id;
			mapperService.map(descriptionUnit, copy);
			// adjust room ids
			if (descriptionUnit.rooms && Array.isArray(descriptionUnit.rooms)) {
				for (let room of descriptionUnit.rooms) {
					room.id = uuid.v4();
				}
			}
			copy.name = "Copy of " + copy.name;
			copy.id = copyId;
			return copy;
		},

		createNewSpaceUnit: function () {
			var unit = new SpaceUnit();
			unit.id = uuid.v4();
			return unit;
		},

		createNewVolumeUnit: function () {
			var unit = new VolumeUnit();
			unit.id = uuid.v4();
			return unit;
		}
	};

	return root;
});