﻿import {RoomDto} from './RoomDto'

export function DescriptionUnitDto() {
	this.Id = '';
	this.Name = '';
	this.GeneralTechnicalEquipment = {
		Heating: '',
		HotWaterInstallations: ''
	};
	this.Description = {
		UseIndividualRoomDescription: false,
		Floorings: '',
		Panelling: '',
		Ceiling: '',
		KitchenEquipment: '',
		Windows: '',
		Doors: '',
		PreliminaryRemarks: '',
		SpecialFacility: '',
		Sanitation: ''
	};
	this.Rooms = [];
}

DescriptionUnitDto.getTyping = function () {
    return {
        Rooms: {
            _isArray: true,
            _type: RoomDto
        }
    }
};