﻿// (function () {

	angular.module("services").service("httpInterceptorService", function ($rootScope, $q) {
		var self = this;

		this.request = function(config) {
			if ($rootScope.apiToken)
				config.headers['Authorization'] = "Bearer " + $rootScope.apiToken;
				
			return config;
		};

		this.responseError = function (response) {
			console.log("error", response);
			if (response.status === 401) {
				$rootScope.$broadcast('httpUnauthorized');
				console.log("UNAUTHENTICATED");
				
			}
			return $q.reject(response);
		};
	});

// })();

// angular.module("services").service("httpInterceptorService", function ($rootScope, $q) {
	
// 	  // optional method
// 	  'request': function(config) {
// 		// do something on success
// 		return config;
// 	  },
  
// 	  // optional method
// 	 'requestError': function(rejection) {
// 		// do something on error
// 		if (canRecover(rejection)) {
// 		  return responseOrNewPromise
// 		}
// 		return $q.reject(rejection);
// 	  },
  
  
  
// 	  // optional method
// 	  'response': function(response) {
// 		// do something on success
// 		return response;
// 	  },
  
// 	  // optional method
// 	 'responseError': function(rejection) {
// 			if (response.status === 401) {
// 				$rootScope.$broadcast('httpUnauthorized');
// 				console.log("UNAUTHENTICATED");
// 				return $q.reject(response);
// 			}
// 	  }
// 	};
//   });
