﻿angular.module('directives').directive('ngEnter', function () {
	return function (scope, element, attrs) {
		element.bind("keydown", function (event) {
			if (event.which === 13) {
				event.preventDefault();
				event.stopPropagation();
				scope.$apply(function () {
					scope.$eval(attrs.ngEnter);
				});

				
			}
		});
	};
});