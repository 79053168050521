import {Space} from '../models/Space'

angular.module("controllers").controller("projectSpaceController", function ($scope, $controller, project, projectService, itemDataService, ngDialog) {
	$controller("projectBaseController", { $scope: $scope, project: project, itemDataService: itemDataService });

	$scope.newSpace = [];
	$scope.defaultPlasterDeduction = Space.defaultPlasterDeduction;

	$scope.project.spaceCalculation.spaceUnits.forEach(function (spaceUnit) {
		$scope.newSpace[spaceUnit.id] = new Space();
	}, this);



	$scope.itemsFor = function (path) {
		return itemDataService.getFor(path);
	};

	$scope.addSpaceUnit = function () {
		var unit = projectService.createNewSpaceUnit();
		$scope.project.spaceCalculation.spaceUnits.push(unit);
		$scope.newSpace[unit.id] = new Space();
	};

	$scope.addSpace = function (spaceUnitId) {
		$scope.project.getSpaceUnitById(spaceUnitId).spaces.push($scope.newSpace[spaceUnitId]);
		$scope.newSpace[spaceUnitId] = new Space();
	};

	$scope.deleteSpace = function (spaceUnitId, space) {
		var spaceUnit = $scope.project.getSpaceUnitById(spaceUnitId);
		spaceUnit.deleteSpace(space);
	};

	$scope.togglePlasterDeduction = function (spaceUnitId) {
		if ($scope.newSpace[spaceUnitId].plasterDeduction > 0) {
			$scope.newSpace[spaceUnitId].plasterDeduction = 0;
		}
		else {
			$scope.newSpace[spaceUnitId].plasterDeduction = Space.defaultPlasterDeduction;
		}
	};

	$scope.calculateFullSpace = function () {
		var sum = 0;
		angular.forEach($scope.project.spaceCalculation.spaceUnits, function (spaceUnit) {
			sum += spaceUnit.area();
		});

		return sum;
	};

	$scope.deleteSpaceUnit = function (spaceUnit) {
		var index = $scope.project.spaceCalculation.spaceUnits.indexOf(spaceUnit);
		if (index > -1)
			$scope.project.spaceCalculation.spaceUnits.splice(index, 1);
	};

});