﻿angular.module("controllers").controller("chooseFloorDialogController", function ($scope, project, close) {
	
	$scope.closeThisDialog = close;
	
	$scope.selectedFloor = "";
	$scope.floors = project.floors;
	
	$scope.selectFloor = function (floor) {
		$scope.selectedFloor = floor;
		console.log("doing", floor);
	};

	$scope.done = function () {
		console.log($scope.selectedFloor);
		$scope.closeThisDialog($scope.selectedFloor);
	};

	$scope.cancel = function () {
		$scope.closeThisDialog(false);
	};

	
});