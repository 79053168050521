angular.module("controllers").controller("descriptionUnitDescriptionController", function ($scope, $q, $controller, project, itemDataService, ModalService, $state, projectService, descriptionUnit) {
	$controller("descriptionUnitBaseController", { $scope: $scope, project: project, itemDataService: itemDataService, descriptionUnit: descriptionUnit });

	$scope.addFloorsFromTemplate = function (template) {
		var floorTypes = [];
		if (template == 'template1') {
			floorTypes.push(Floor.Types.Basement.id,
				Floor.Types.Groundfloor.id,
				Floor.Types.Attic.id);
		}
		else if (template == 'template2') {
			floorTypes.push(Floor.Types.Basement.id,
				Floor.Types.Groundfloor.id,
				Floor.Types.Upstairs.id,
				Floor.Types.Attic.id);
		}
		else if (template == 'template3') {
			floorTypes.push(Floor.Types.Basement.id,
				Floor.Types.Groundfloor.id,
				Floor.Types.Upstairs.id,
				Floor.Types.Upstairs.id,
				Floor.Types.Attic.id);
		}

		for (let floorType of floorTypes) {
			let floor = projectService.createNewFloor(floorType, $scope.project);
			if (floor != false) {
				$scope.project.floors.push(floor);
			}
		}
	};

	$scope.addFloor = function () {


		ModalService.showModal({
			template: require('./chooseFloorTypeDialog.html'),
			controller: 'chooseFloorTypeDialogController',
			closeDelay:500, 
			preClose: (modal) => { modal.element.modal('hide');}
		})
		.then(function (modal) {
			modal.element.modal();
			return modal.close;
		})
		.then(function (res) {
			if (res != false && res.length > 0) {
				var floor = projectService.createNewFloor(res, $scope.project);
				if (floor != false) {
					$scope.project.floors.push(floor);
					//$state.go("project-floor", { floorId: floor.id });
				}
			}
			return;
		});
	};

	$scope.addRoom = function () {
		// input room name dialog
		ModalService.showModal({
			template: require('./inputRoomNameDialog.html'),
			controller: 'inputRoomNameDialogController',
			closeDelay:500, 
			preClose: (modal) => { modal.element.modal('hide');},
			inputs: {
				itemDataService: itemDataService
			}
		})
		.then(function (modal) {
			modal.element.modal();
			return modal.close;
		})
		.then(function (res) {
			if (res != false && res.length > 0) {
				var room = projectService.createNewRoom($scope.descriptionUnit, res);
				if (room != false) {
					return room;
				}
			}
			return $q.reject();
		})
		// choose floor dialog
		.then(function (room) {
			return ModalService.showModal({
				template: require('./chooseFloorDialog.html'),
				controller: 'chooseFloorDialogController',
				closeDelay:500, 
				preClose: (modal) => { modal.element.modal('hide');},
				inputs: {
					project: project
				}
			})
			.then(function (modal) {
				modal.element.modal();
				return modal.close;
			})
			.then(function (res) {
				if (res != null) {
					room.floorId = res.id;
					return room;
				}
				return $q.reject();
			})
		})
		// add room
		.then(function (room) {
			$scope.descriptionUnit.rooms.push(room);
		});
	};

});