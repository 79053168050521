﻿import {Volume} from './Volume'

export function VolumeUnit()
{
	this.id = '';
	this.name = '';
	this.volumes = [];

	this.deleteVolume = function (volume) {
		var index = this.volumes.indexOf(volume);
		if (index > -1)
			this.volumes.splice(index, 1);
	};

	this.volume = function () {
		var res = 0;
		for (var index in this.volumes) {
			res += this.volumes[index].calculate();
		}
		return res;
	};
}

VolumeUnit.getTyping = function () {
    return {
        volumes: {
            _isArray: true,
            _type: Volume
        }
    };
};