﻿export function Volume() {
	this.name = '';
	this.width = '';
	this.height = '';
	this.length = '';
	this.isNegative = false;

	this.calculate = function () {
		var vol = this.width * this.height * this.length;
		if (this.isNegative)
			vol *= -1;
		return vol;
	};
}