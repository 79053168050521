angular.module("controllers").controller("floorController", function ($scope, $controller, project, floor, itemDataService, ngDialog, $state) {
	$controller("projectBaseController", { $scope: $scope, project: project, itemDataService: itemDataService });
	$scope.floor = floor;
	$scope.fitoutAreaVisible = function () {
		return $scope.floor.fitout.value;
	};

	$scope.deleteFloor = function () {
		$scope.project.deleteFloor($scope.floor);
		$state.go('project-building-construction', { projectId: $scope.project.id });
	};
});