﻿import {SpaceDto} from './SpaceDto'

export function SpaceUnitDto()
{
	this.Id = '';
	this.Name = '';
	this.DescriptionUnitId = '';
	this.Spaces = [];
}

SpaceUnitDto.getTyping = function () {
    return {
        Spaces: {
            _isArray: true,
            _type: SpaceDto
        }
    };
};