﻿angular.module("controllers").controller("projectBaseController", function ($scope, project, itemDataService, projectService, appService) {
	$scope.project = project;

	$scope.showSideBar = true;
	$scope.menuVisible = false;

	$scope.itemsFor = function (path) {
		return itemDataService.getFor(path);
	};
	console.log("start watch");
	$scope.$watch('project', function (newValue, oldValue) {
		console.log("fire");
		
		if (angular.equals(newValue, oldValue))
			return;

		console.log("action");
		projectService.save(newValue);
	}, true);

	$scope.$on('$stateChangeStart', function () {
		$scope.showSideBar = true;
	});

	appService.topBar.visible = true;

	$scope.toggleMenu = function () {
		console.log("toggle");
		$scope.menuVisible = ! $scope.menuVisible;
	};
});