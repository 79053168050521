﻿import {Room} from './Room'

export function DescriptionUnit() {
	this.id = '';
	this.name = '';
	this.generalTechnicalEquipment = {
		heating: '',
		hotWaterInstallations: ''
	};
	this.description = {
		useIndividualRoomDescription: false,
		floorings: '',
		panelling: '',
		ceiling: '',
		kitchenEquipment: '',
		windows: '',
		doors: '',
		preliminaryRemarks: '',
		specialFacility: '',
		sanitation: ''
	};
	this.rooms = [];

	this.deleteRoom = function (room) {
		var index = this.rooms.indexOf(room);
		if (index > -1) {
			this.rooms.splice(index, 1);
			return true;
		}
		return false;
	};
}

DescriptionUnit.getTyping = function () {
    return {
        rooms: {
            _isArray: true,
            _type: Room
        }
    }
};
