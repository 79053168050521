module.exports = ﻿{
	"floor": {
		"staircase": [
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "Holztreppe",
				"separator": " "
			},
			{
				"text": "Stahltreppe",
				"separator": " "
			},
			{
				"text": "Stahlbetontreppe",
				"separator": " "
			},
			{
				"text": "Stahl / Holzkonstruktion",
				"separator": " "
			},
			{
				"text": "Natursteintreppe",
				"separator": " "
			},
			{
				"text": "Steinzeugtreppe",
				"separator": " "
			},
			{
				"text": "Waschbetonplatten",
				"separator": " "
			},
			{
				"text": "Terrazzo",
				"separator": " "
			},
			{
				"text": "Belag:",
				"separator": " "
			},
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "Anstrich",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Granit",
				"separator": " "
			},
			{
				"text": "Marmor",
				"separator": " "
			},
			{
				"text": "Naturstein",
				"separator": " "
			},
			{
				"text": "Bruchsteine",
				"separator": " "
			},
			{
				"text": "Schiefer",
				"separator": " "
			},
			{
				"text": "Kunststein",
				"separator": " "
			},
			{
				"text": "Werkstein",
				"separator": " "
			},
			{
				"text": "Holz",
				"separator": " "
			},
			{
				"text": "(Buche)",
				"separator": " "
			},
			{
				"text": "(Eiche)",
				"separator": " "
			},
			{
				"text": "PVC",
				"separator": " "
			},
			{
				"text": "Teppich",
				"separator": " "
			},
			{
				"text": "Laminat",
				"separator": " "
			},
			{
				"text": "Parkett",
				"separator": " "
			},
			{
				"text": "Geländer:",
				"separator": " "
			},
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "einfaches",
				"separator": " "
			},
			{
				"text": "besseres",
				"separator": " "
			},
			{
				"text": "exklusives",
				"separator": " "
			},
			{
				"text": "Holzgeländer",
				"separator": " "
			},
			{
				"text": "Stahlgeländer",
				"separator": " "
			},
			{
				"text": "Edelstahlgeländer",
				"separator": " "
			},
			{
				"text": "Stahl / Glasgeländer",
				"separator": " "
			},
			{
				"text": "künstlerisch gestalteter Handlauf",
				"separator": " "
			},
			{
				"text": "einfacher Handlauf",
				"separator": " "
			},
			{
				"text": "Holz",
				"separator": " "
			},
			{
				"text": "Stahl",
				"separator": " "
			},
			{
				"text": "hochwertiger Handlauf",
				"separator": " "
			},
			{
				"text": "künstlerisch gestaltetes Geländer",
				"separator": " "
			}
		],
		"exteriorStaircase": [
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "Holztreppe",
				"separator": " "
			},
			{
				"text": "Stahltreppe",
				"separator": " "
			},
			{
				"text": "Stahlbetontreppe",
				"separator": " "
			},
			{
				"text": "Stahl / Holzkonstruktion",
				"separator": " "
			},
			{
				"text": "Natursteintreppe",
				"separator": " "
			},
			{
				"text": "Waschbetonplatten",
				"separator": " "
			},
			{
				"text": "Terrazzo",
				"separator": " "
			},
			{
				"text": "Belag:",
				"separator": " "
			},
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "Anstrich",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Granit",
				"separator": " "
			},
			{
				"text": "Marmor",
				"separator": " "
			},
			{
				"text": "Naturstein",
				"separator": " "
			},
			{
				"text": "Bruchsteine",
				"separator": " "
			},
			{
				"text": "Schiefer",
				"separator": " "
			},
			{
				"text": "Kunststein",
				"separator": " "
			},
			{
				"text": "Werkstein",
				"separator": " "
			}
		],
		"storeyCeiling": [
			{
				"text": "Stahlbeton",
				"separator": " "
			},
			{
				"text": "Ortbeton",
				"separator": " "
			},
			{
				"text": "Holzbalken",
				"separator": " "
			},
			{
				"text": "Hohlkörperdecke mit Beton",
				"separator": " "
			},
			{
				"text": "Gewölbedecke aus Mauerwerk",
				"separator": " "
			},
			{
				"text": "Fertigteildecke",
				"separator": " "
			},
			{
				"text": "Trägerkappendecke",
				"separator": " "
			},
			{
				"text": "Trägerdecke aus Tonplatten und Aufbeton",
				"separator": " "
			},
			{
				"text": "Trägerdecke mit Betonausfüllung",
				"separator": " "
			},
			{
				"text": "Spannbetonfertigteile",
				"separator": " "
			},
			{
				"text": "Gewölbedecke, Stahlträger und Mauerwerk",
				"separator": " "
			}
		],
		"basement": {
			"damage": [
				{
					"text": "Ansatz erfolgt in den besonderen objektspezifischen Grundstücksmerkmale im Gutachten.",
					"separator": " "
				},
				{
					"text": "keine erkennbar",
					"separator": " "
				},
				{
					"text": "keine wesentlichen erkennbar",
					"separator": " "
				},
				{
					"text": "tlw.",
					"separator": " "
				},
				{
					"text": "überwiegend",
					"separator": " "
				},
				{
					"text": "Salzausblühungen im KG",
					"separator": " "
				},
				{
					"text": "Putzschäden",
					"separator": " "
				},
				{
					"text": "Schimmelbildung",
					"separator": " "
				},
				{
					"text": "Hausschwamm",
					"separator": " "
				},
				{
					"text": "Balkonunterseite",
					"separator": " "
				},
				{
					"text": "Feuchtigkeitsschäden",
					"separator": " "
				},
				{
					"text": "Feuchtigkeitsschäden im KG",
					"separator": " "
				},
				{
					"text": "Risse in(m)",
					"separator": " "
				},
				{
					"text": "Wasserschaden in",
					"separator": " "
				},
				{
					"text": "Decke",
					"separator": " "
				},
				{
					"text": "Wand",
					"separator": " "
				},
				{
					"text": "Stahlträger angerostet",
					"separator": " "
				},
				{
					"text": "Stahlträger stark verrostet",
					"separator": " "
				},
				{
					"text": "Stahlträger stark verrostet, Standsicherheit gefährdet",
					"separator": " "
				},
				{
					"text": "allgemein geringe Kopfhöhe",
					"separator": " "
				},
				{
					"text": "geringe Kopfhöhe der Kellertreppe",
					"separator": " "
				}
			],
			"typeOfConstruction": [
				{
					"text": "Mauerwerk",
					"separator": " "
				},
				{
					"text": "Bruchsteinmauerwerk",
					"separator": " "
				},
				{
					"text": "Hohlblockstein",
					"separator": " "
				},
				{
					"text": "Kalksandstein",
					"separator": " "
				},
				{
					"text": "Ziegelstein",
					"separator": " "
				},
				{
					"text": "Ortbeton",
					"separator": " "
				},
				{
					"text": "Betonfertigteile",
					"separator": " "
				},
				{
					"text": "Abdichtung als Außendichtung mit Bitumenbahnen (sog. \"schwarze Wanne\") ausgeführt",
					"separator": " "
				},
				{
					"text": "massive Lichtschächte ",
					"separator": " "
				},
				{
					"text": "Lichtschächte aus Kunststoff",
					"separator": " "
				}
			],
			"fitout": {
				"floor": [
					{
						"text": "Betonboden",
						"separator": " "
					},
					{
						"text": "Estrich",
						"separator": " "
					},
					{
						"text": "tlw.",
						"separator": " "
					},
					{
						"text": "Fliesen",
						"separator": " "
					},
					{
						"text": "PVC",
						"separator": " "
					},
					{
						"text": "Marmor",
						"separator": " "
					},
					{
						"text": "Klinker",
						"separator": " "
					},
					{
						"text": "Naturstein",
						"separator": " "
					},
					{
						"text": "Steinzeug",
						"separator": " "
					},
					{
						"text": "Terrazzo",
						"separator": " "
					},
					{
						"text": "in einfacher Preisklasse",
						"separator": " "
					},
					{
						"text": "in mittlerer Preisklasse",
						"separator": " "
					},
					{
						"text": "in gehobener Preisklasse",
						"separator": " "
					},
					{
						"text": "Lehmboden",
						"separator": " "
					}
				],
				"walls": [
					{
						"text": "Gips",
						"separator": " "
					},
					{
						"text": "Gipskartonplatte",
						"separator": " "
					},
					{
						"text": "Anstrich",
						"separator": " "
					},
					{
						"text": "Kalk, Leim- bzw. Binderfarbenanstrich",
						"separator": " "
					},
					{
						"text": "Tapete",
						"separator": " "
					},
					{
						"text": "Reibeputz",
						"separator": " "
					},
					{
						"text": "Strukturputz",
						"separator": " "
					},
					{
						"text": "Rollputz",
						"separator": " "
					},
					{
						"text": "Profilholz",
						"separator": " "
					},
					{
						"text": "Paneele",
						"separator": " "
					},
					{
						"text": "Fliesen",
						"separator": " "
					},
					{
						"text": "Fliesen im Bad",
						"separator": " "
					},
					{
						"text": "ca. X m hoch",
						"separator": " "
					},
					{
						"text": "raumhoch",
						"separator": " "
					},
					{
						"text": "darüber",
						"separator": " "
					},
					{
						"text": "PVC-Verkleidungen",
						"separator": " "
					},
					{
						"text": "in einfacher Preisklasse",
						"separator": " "
					},
					{
						"text": "in mittlerer Preisklasse",
						"separator": " "
					},
					{
						"text": "in gehobener Preisklasse",
						"separator": " "
					},
					{
						"text": "in oberer Preisklasse",
						"separator": " "
					},
					{
						"text": "mit Bordüre",
						"separator": " "
					}
				],
				"ceiling": [
					{
						"text": "Deckenunterkonstruktion mit:",
						"separator": " "
					},
					{
						"text": "tlw. im",
						"separator": " "
					},
					{
						"text": "Rohbauzustand",
						"separator": " "
					},
					{
						"text": "gespachtelt",
						"separator": " "
					},
					{
						"text": "Gips",
						"separator": " "
					},
					{
						"text": "Verputzt (mit)",
						"separator": " "
					},
					{
						"text": "Anstrich",
						"separator": " "
					},
					{
						"text": "Kalk-, Leim- bzw. Binderfarbenanstrich",
						"separator": " "
					},
					{
						"text": "Tapete",
						"separator": " "
					},
					{
						"text": "Styroporplatten",
						"separator": " "
					},
					{
						"text": "Strukturputz mit sichtbaren Holzbalken",
						"separator": " "
					},
					{
						"text": "Holzwerkstoffplatten",
						"separator": " "
					},
					{
						"text": "Profilholz",
						"separator": " "
					},
					{
						"text": "Paneele",
						"separator": " "
					},
					{
						"text": "Reibeputz",
						"separator": " "
					},
					{
						"text": "Strukturputz",
						"separator": " "
					},
					{
						"text": "Rollputz",
						"separator": " "
					},
					{
						"text": "in einfacher Preisklasse",
						"separator": " "
					},
					{
						"text": "in mittlerer Preisklasse",
						"separator": " "
					},
					{
						"text": "in gehobener Preisklasse",
						"separator": " "
					}
				],
				"windows": [
					{
						"text": "ohne",
						"separator": " "
					},
					{
						"text": "tlw. ohne",
						"separator": " "
					},
					{
						"text": "überwiegend",
						"separator": " "
					},
					{
						"text": "Fenster aus Stahl mit",
						"separator": " "
					},
					{
						"text": "Fenster aus Kunststoff mit",
						"separator": " "
					},
					{
						"text": "Fenster aus Aluminium mit",
						"separator": " "
					},
					{
						"text": "Fenster aus Holz",
						"separator": " "
					},
					{
						"text": "Fenster außen aus Aluminium, innen aus Holz",
						"separator": " "
					},
					{
						"text": "Einfachverglasung",
						"separator": " "
					},
					{
						"text": "Doppelverglasung",
						"separator": " "
					},
					{
						"text": "Isolierverglasung",
						"separator": " "
					},
					{
						"text": "Dreifachverglasung",
						"separator": " "
					},
					{
						"text": "tlw. mit innen liegenden Sprossen",
						"separator": " "
					},
					{
						"text": "tlw. mit aufgesetzten Sprossen",
						"separator": " "
					},
					{
						"text": "große Schiebeelemente",
						"separator": " "
					},
					{
						"text": "auch raumhohe Verglasung",
						"separator": " "
					},
					{
						"text": "ohne Rollladen",
						"separator": " "
					},
					{
						"text": "tlw. ohne Rollladen",
						"separator": " "
					},
					{
						"text": "Rollladen aus Kunststoff",
						"separator": " "
					},
					{
						"text": "Rollladen aus Holz",
						"separator": " "
					},
					{
						"text": "Rollladen mit E-Antrieb",
						"separator": " "
					},
					{
						"text": "Rollladen tlw. mit E-Antrieb",
						"separator": " "
					},
					{
						"text": "Fensterbänke innen:",
						"separator": " "
					},
					{
						"text": "Fensterbänke außen:",
						"separator": " "
					},
					{
						"text": "ohne",
						"separator": " "
					},
					{
						"text": "tlw. ohne",
						"separator": " "
					},
					{
						"text": "überwiegend",
						"separator": " "
					},
					{
						"text": "Holz / Werzalit",
						"separator": " "
					},
					{
						"text": "aus Faserzement",
						"separator": " "
					},
					{
						"text": "Fliesen",
						"separator": " "
					},
					{
						"text": "Marmor",
						"separator": " "
					},
					{
						"text": "Betonwerkstein",
						"separator": " "
					},
					{
						"text": "Kunststein",
						"separator": " "
					},
					{
						"text": "Naturstein",
						"separator": " "
					},
					{
						"text": "Terrazzo",
						"separator": " "
					},
					{
						"text": "Aluminium",
						"separator": " "
					},
					{
						"text": "Granit",
						"separator": " "
					},
					{
						"text": "Klinker",
						"separator": " "
					},
					{
						"text": "Mauerwerk mit Verputz",
						"separator": " "
					},
					{
						"text": "Sandstein",
						"separator": " "
					}
				],
				"doors": [
					{
						"text": "tlw. aus",
						"separator": " "
					},
					{
						"text": "tlw.",
						"separator": " "
					},
					{
						"text": "aus Holzlatten",
						"separator": " "
					},
					{
						"text": "Holztüren",
						"separator": " "
					},
					{
						"text": "FH-Tür(n)",
						"separator": " "
					},
					{
						"text": "aus Glas",
						"separator": " "
					},
					{
						"text": "Glastüren",
						"separator": " "
					},
					{
						"text": "Kunststoff",
						"separator": " "
					},
					{
						"text": "Kunststofftüren",
						"separator": " "
					},
					{
						"text": "tlw. flächenbündige Ausführung",
						"separator": " "
					},
					{
						"text": "mit Glasfüllungen",
						"separator": " "
					},
					{
						"text": "tlw. mit Glasfüllungen",
						"separator": " "
					},
					{
						"text": "Schiebetüren",
						"separator": " "
					},
					{
						"text": "Schiebetüren mit Glasfüllungen",
						"separator": " "
					},
					{
						"text": "Flügeltüren",
						"separator": " "
					},
					{
						"text": "Flügeltüren mit Glasfüllungen",
						"separator": " "
					},
					{
						"text": "tlw. mit Falltüren",
						"separator": " "
					},
					{
						"text": "Stahlzargen",
						"separator": " "
					},
					{
						"text": "Stahleckzargen",
						"separator": " "
					},
					{
						"text": "Holzzargen",
						"separator": " "
					},
					{
						"text": "tlw. ohne Türblätter",
						"separator": " "
					},
					{
						"text": "einfache",
						"separator": " "
					},
					{
						"text": "mittlere",
						"separator": " "
					},
					{
						"text": "hochwertige",
						"separator": " "
					},
					{
						"text": "sehr hochwertige",
						"separator": " "
					},
					{
						"text": "exklusive",
						"separator": " "
					},
					{
						"text": "Ausführung",
						"separator": " "
					}
				]
			}
		},
		"attic": {
			"extension": [
				{
					"text": "Das Dachgeschoss ist ausgebaut.",
					"separator": " "
				},
				{
					"text": "Das Dachgeschoss ist nicht ausgebaut.",
					"separator": " "
				},
				{
					"text": "Das Dachgeschoss ist teilweise ausgebaut.",
					"separator": " "
				}
			],
			"optionsOfExtension": [
				{
					"text": "Das Dachgeschoss ist ausbaufähig.",
					"separator": " "
				},
				{
					"text": "Das Dachgeschoss ist nicht ausbaufähig.",
					"separator": " "
				},
				{
					"text": "Eine diesbezügliche Baugenehmigung liegt vor.",
					"separator": " "
				},
				{
					"text": "Eine diesbezügliche Baugenehmigung liegt nicht vor.",
					"separator": " "
				}
			],
			"staircase": [
				{
					"text": "ohne Treppe",
					"separator": " "
				},
				{
					"text": "ohne Einstiegsmöglichkeit",
					"separator": " "
				},
				{
					"text": "Einstiegsluke (ca. 50 cm x 60 cm)",
					"separator": " "
				},
				{
					"text": "Einschubtreppe aus",
					"separator": " "
				},
				{
					"text": "Holz",
					"separator": " "
				},
				{
					"text": "Aluminium",
					"separator": " "
				},
				{
					"text": "Stahl",
					"separator": " "
				},
				{
					"text": "als Raumspartreppe",
					"separator": " "
				}
			]
		},
		"types": [
			{
				"id": "basement",
				"name": "Kellergeschoss"
			},
			{
				"id": "ground-floor",
				"name": "Erdgeschoss"
			},
			{
				"id": "upstairs",
				"name": "Obergeschoss"
			},
			{
				"id": "attic",
				"name": "Dachgeschoss"
			}

		]
	},
	"room": {
		"types": [
			{ "text": "Diele" },
			{ "text": "Windfang" },
			{ "text": "Flur" },
			{ "text": "Bad" },
			{ "text": "Duschbad" },
			{ "text": "WC" },
			{ "text": "Gäste-WC" },
			{ "text": "Esszimmer" },
			{ "text": "Wohn-Esszimmer" },
			{ "text": "Küche" },
			{ "text": "Schlafzimmer" },
			{ "text": "Arbeitszimmer" },
			{ "text": "Ankleide" },
			{ "text": "Wohnzimmer" },
			{ "text": "Kaminzimmer" },
			{ "text": "Kinderzimmer" },
			{ "text": "Gästezimmer" },
			{ "text": "Computerraum" },
			{ "text": "Galerie" },
			{ "text": "Wintergarten" },
			{ "text": "Dachterrasse" },
			{ "text": "Vorratsraum" },
			{ "text": "Hauswirtschaftsraum" },
			{ "text": "Abstellraum" },
			{ "text": "Gastraum" },
			{ "text": "Balkon" },
			{ "text": "Terrasse" },
			{ "text": "Loggia" },
			{ "text": "Technikraum" },
			{ "text": "Musikzimmer" },
			{ "text": "Zimmer" },
			{ "text": "Verkaufsraum" },
			{ "text": "Putzraum" },
			{ "text": "Freisitz" },
			{ "text": "Ladenlokal" },
			{ "text": "Kellerraum" },
			{ "text": "Lagerraum" },
			{ "text": "Sauna" },
			{ "text": "Hobbyraum" },
			{ "text": "Heizraum" },
			{ "text": "Partyraum" },
			{ "text": "Öllagerraum" },
			{ "text": "Schwimmbad" },
			{ "text": "Hausanschlussraum" },
			{ "text": "Kraftraum" },
			{ "text": "Gewölbekeller" },
			{ "text": "Weinkeller" },
			{ "text": "Waschküche" },
			{ "text": "Werkstatt" },
			{ "text": "Trockenraum" },
			{ "text": "Aufenthaltsraum" },
			{ "text": "Nebenraum" },
			{ "text": "Gaststätten-Küche" },
			{ "text": "Büroraum" },
			{ "text": "Kopierraum" },
			{ "text": "Personal-WC" },
			{ "text": "Besucher-WC" },
			{ "text": "Herren-WC" },
			{ "text": "Damen-WC" },
			{ "text": "Behinderten-WC" },
			{ "text": "Teeküche" },
			{ "text": "Archiv" },
			{ "text": "Konferenzraum" },
			{ "text": "Rezeption" },
			{ "text": "Behandlungszimmer" },
			{ "text": "Wartezimmer" },
			{ "text": "Arztzimmer" },
			{ "text": "Labor" },
			{ "text": "Röntgenraum" },
			{ "text": "Entwicklungsraum" }
		],
		"details": {

			"orientation": [
				{
					"text": "straßenseitig",
					"separator": " "
				},
				{
					"text": "gartenseitig",
					"separator": " "
				},
				{
					"text": "innenliegend",
					"separator": " "
				},
				{
					"text": "giebelseitig",
					"separator": " "
				}
			]
		}
	},
	"description": {
		"floorings": [
			{
				"text": "ohne Belag",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "tlw. im",
				"separator": " "
			},
			{
				"text": "Rohbauzustand",
				"separator": " "
			},
			{
				"text": "unterschiedliche Belagshöhen",
				"separator": " "
			},
			{
				"text": "Betonboden",
				"separator": " "
			},
			{
				"text": "Estrich",
				"separator": " "
			},
			{
				"text": "Holzdielen",
				"separator": " "
			},
			{
				"text": "Spanplatten",
				"separator": " "
			},
			{
				"text": "OSB-Platten",
				"separator": " "
			},
			{
				"text": "Korkbelag",
				"separator": " "
			},
			{
				"text": "Laminat",
				"separator": " "
			},
			{
				"text": "Parkett",
				"separator": " "
			},
			{
				"text": "Kleinparkett",
				"separator": " "
			},
			{
				"text": "Stabparkett",
				"separator": " "
			},
			{
				"text": "Teppich",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Textilbelag",
				"separator": " "
			},
			{
				"text": "PVC",
				"separator": " "
			},
			{
				"text": "Linoleum",
				"separator": " "
			},
			{
				"text": "Gummibelag",
				"separator": " "
			},
			{
				"text": "Kunststeinplatten",
				"separator": " "
			},
			{
				"text": "Verbundsteine",
				"separator": " "
			},
			{
				"text": "Kleinmosaik",
				"separator": " "
			},
			{
				"text": "Marmor",
				"separator": " "
			},
			{
				"text": "Granit",
				"separator": " "
			},
			{
				"text": "Naturstein",
				"separator": " "
			},
			{
				"text": "Steinzeug",
				"separator": " "
			},
			{
				"text": "Terrazzo",
				"separator": " "
			},
			{
				"text": "Kunstharzbeschichtung",
				"separator": " "
			},
			{
				"text": "in einfacher Preisklasse",
				"separator": " "
			},
			{
				"text": "in mittlerer Preisklasse",
				"separator": " "
			},
			{
				"text": "in gehobener Preisklasse",
				"separator": " "
			},
			{
				"text": "in oberer Preisklasse",
				"separator": " "
			},
			{
				"text": "Balkon:",
				"separator": " "
			},
			{
				"text": "Terrasse:",
				"separator": " "
			},
			{
				"text": "Loggia:",
				"separator": " "
			},
			{
				"text": "Geländer:",
				"separator": " "
			},
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "einfaches",
				"separator": " "
			},
			{
				"text": "besseres",
				"separator": " "
			},
			{
				"text": "exklusives",
				"separator": " "
			},
			{
				"text": "Holzgeländer",
				"separator": " "
			},
			{
				"text": "Stahlgeländer",
				"separator": " "
			},
			{
				"text": "Stahl / Holz - Geländer",
				"separator": " "
			},
			{
				"text": "Edelstahlgeländer",
				"separator": " "
			},
			{
				"text": "Stahl- / Glasgeländer",
				"separator": " "
			},
			{
				"text": "künstlerisch gestaltetes Geländer",
				"separator": " "
			},
			{
				"text": "einfacher Handlauf",
				"separator": " "
			},
			{
				"text": "hochwertiger Handlauf",
				"separator": " "
			},
			{
				"text": "künstlerisch gestalteter Handlauf",
				"separator": " "
			}
		],
		"panelling": [
			{
				"text": "ohne Belag",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "tlw. im",
				"separator": " "
			},
			{
				"text": "Rohbauzustand",
				"separator": " "
			},
			{
				"text": "nur Mauerwerk",
				"separator": " "
			},
			{
				"text": "Gips",
				"separator": " "
			},
			{
				"text": "Gipskartonplatten",
				"separator": " "
			},
			{ "text": "Anstrich" },
			{
				"text": "Anstrich (Kalk, Silikat bzw. Naturharzdispersionsfarben)",
				"separator": " "
			},
			{
				"text": "Kalk-, Leim- bzw. Binderfarbenanstrich",
				"separator": " "
			},
			{
				"text": "Tapete",
				"separator": " "
			},
			{
				"text": "Stoffbespannungen",
				"separator": " "
			},
			{
				"text": "Reibeputz",
				"separator": " "
			},
			{
				"text": "Strukturputz",
				"separator": " "
			},
			{
				"text": "Rollputz",
				"separator": " "
			},
			{
				"text": "Profilholz",
				"separator": " "
			},
			{
				"text": "Paneele",
				"separator": " "
			},
			{
				"text": "Korkbelag",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Fliesen im Bad",
				"separator": " "
			},
			{
				"text": "ca. ${Höhe in m} m hoch",
				"separator": " "
			},
			{
				"text": "raumhoch",
				"separator": " "
			},
			{
				"text": "mit Bordüre",
				"separator": " "
			},
			{
				"text": "darüber",
				"separator": " "
			},
			{
				"text": "PVC-Verkleidung",
				"separator": " "
			},
			{
				"text": "in einfacher Preisklasse",
				"separator": " "
			},
			{
				"text": "in mittlerer Preisklasse",
				"separator": " "
			},
			{
				"text": "in gehobener Preisklasse",
				"separator": " "
			},
			{
				"text": "in oberer Preisklasse",
				"separator": " "
			},
			{
				"text": "Wandbemalungen",
				"separator": " "
			},
			{
				"text": "Ölfarbanstrich",
				"separator": " "
			},
			{
				"text": "Fliesenspiegel",
				"separator": " "
			},
			{
				"text": "Fliesenspiegel in der Küche",
				"separator": " "
			},
			{
				"text": "in Spachteltechnik",
				"separator": " "
			},
			{
				"text": "Kork",
				"separator": " "
			},
			{
				"text": "Granit",
				"separator": " "
			},
			{
				"text": "Marmor",
				"separator": " "
			}
		],
		"ceiling": [
			{ "text": "tlw." },
			{
				"text": "Deckenkonstruktion mit:",
				"separator": " "
			},
			{
				"text": "tlw. im",
				"separator": " "
			},
			{
				"text": "Rohbauzustand",
				"separator": " "
			},
			{
				"text": "gespachtelt",
				"separator": " "
			},
			{
				"text": "Gips",
				"separator": " "
			},
			{
				"text": "Gipskartonplatten",
				"separator": " "
			},
			{
				"text": "Anstrich",
				"separator": " "
			},
			{
				"text": "Kalk-, Leim- bzw. Binderfarbenanstrich",
				"separator": " "
			},
			{
				"text": "Tapete",
				"separator": " "
			},
			{
				"text": "Stoffbespannung",
				"separator": " "
			},
			{
				"text": "Reibeputz",
				"separator": " "
			},
			{
				"text": "Strukturputz",
				"separator": " "
			},
			{
				"text": "Rollputz",
				"separator": " "
			},
			{
				"text": "Profilholz",
				"separator": " "
			},
			{
				"text": "Paneele",
				"separator": " "
			},
			{
				"text": "sichtbare Deckenkonstruktion",
				"separator": " "
			},
			{
				"text": "Holzwerkstoffplatten",
				"separator": " "
			},
			{
				"text": "schalldämmende Unterdecken",
				"separator": " "
			},
			{
				"text": "abgehängte Decke mit Einbauleuchten (mehrere Schaltkreise)",
				"separator": " "
			},
			{
				"text": "abgehängte Decke mit Einbauleuchten",
				"separator": " "
			},
			{
				"text": "abgehängte Decke",
				"separator": " "
			},
			{
				"text": "Odenwalddecke",
				"separator": " "
			},
			{
				"text": "mit Metall- / Aluminiumprofilen",
				"separator": " "
			},
			{
				"text": "Holzkassettendecke",
				"separator": " "
			},
			{
				"text": "Holvertäfelung(en)",
				"separator": " "
			},
			{
				"text": "Hartschaumplatten",
				"separator": " "
			},
			{
				"text": "Styroporplatten",
				"separator": " "
			},
			{
				"text": "in Spachteltechnik",
				"separator": " "
			},
			{
				"text": "Stuckleisten",
				"separator": " "
			},
			{
				"text": "Spanndecke",
				"separator": " "
			},
			{
				"text": "in einfacher Preisklasse",
				"separator": " "
			},
			{
				"text": "in mittlerer Preisklasse",
				"separator": " "
			},
			{
				"text": "in gehobener Preisklasse",
				"separator": " "
			},
			{
				"text": "in oberer Preisklasse",
				"separator": " "
			}
		],
		"sanitation": [
			{ "text": "Gäste / WC:", "separator": " "},
			{ "text": "Bad:", "separator": " "},
			{ "text": "Duschbad:", "separator": " "},
			{ "text": "Kellergeschoss:", "separator": ""},
			{ "text": "Erdgeschoss:", "separator": " "},
			{ "text": "Obergeschoss:", "separator": " "},
			{ "text": "Dachgeschoss:", "separator": " "},
			{ "text": "tlw." },
			{ "text": "fehlende Sanitärobjekte" },
			
			{
				"text": "1 eingebaute Wanne",
				"separator": " "
			},
			{
				"text": "1 freistehende Wanne",
				"separator": " "
			},
			{
				"text": "1 eingebaute Eckwanne",
				"separator": " "
			},
			{
				"text": "1 freistehende Eckwanne",
				"separator": " "
			},
			{
				"text": "1 eingebaute Dusche",
				"separator": " "
			},
			{
				"text": "1 freistehende Dusche",
				"separator": " "
			},
			{
				"text": "1 Duschplatz",
				"separator": " "
			},
			{
				"text": "farbige Sanitärobjekte",
				"separator": " "
			},
			{
				"text": "1 WC",
				"separator": " "
			},
			{
				"text": "1 WC mit Spülkasten",
				"separator": " "
			},
			{
				"text": "1 Stand-WC mit Spülkasten",
				"separator": " "
			},
			{
				"text": "1 wandhängendes WC",
				"separator": " "
			},
			{ "text": "1 Waschbecken" },
			{
				"text": "1 Waschbecken",
				"separator": " "
			},
			{ "text": "1 Ausgussbecken" },
			{
				"text": "1 Doppelwaschbecken",
				"separator": " "
			},
			{
				"text": "1 Waschtisch",
				"separator": " "
			},
			{
				"text": "1 Bidet",
				"separator": " "
			},
			{
				"text": "1 Urinal",
				"separator": " "
			},
			{
				"text": "Waschmaschinenanschluss",
				"separator": " "
			},
			{
				"text": "1 Waschmaschinenanschlüsse",
				"separator": " "
			},
			{
				"text": "Trockneranschluss",
				"separator": " "
			},
			{
				"text": "1 Trockneranschlüsse",
				"separator": " "
			},
			{
				"text": "1 Waschmaschinen- und 1 Trockneranschluss",
				"separator": " "
			},
			{
				"text": "Innenliegend",
				"separator": " "
			},
			{
				"text": "(elektr.) Zwangsentlüftung",
				"separator": " "
			},
			{
				"text": "(elektr.) Zwangsentlüftung nicht vorhanden",
				"separator": " "
			},
			{
				"text": "(elektr.) Zwangsentlüftung defekt",
				"separator": " "
			},
			{
				"text": "überalterte",
				"separator": " "
			},
			{
				"text": "einfache",
				"separator": " "
			},
			{
				"text": "gute",
				"separator": " "
			},
			{
				"text": "hochwertige",
				"separator": " "
			},
			{
				"text": "Ausstattung und Qualität",
				"separator": " "
			},
			{
				"text": "renovierungsbedürftig",
				"separator": " "
			},
			{
				"text": "stark renovierungsbedürftig",
				"separator": " "
			},
			{
				"text": "stark renovierungsbedürftig und so nicht zu nutzen",
				"separator": " "
			},
			{
				"text": "Sanitärobjekte nicht vorhanden",
				"separator": " "
			},
			{
				"text": "Durchlauferhitzer (Elektro)",
				"separator": " "
			},
			{
				"text": "Durchlauferhitzer (Gas)",
				"separator": " "
			},
			{
				"text": "Boiler (Elektro)",
				"separator": " "
			},
			{
				"text": "Boiler (Gas)",
				"separator": " "
			},
			{
				"text": "defekt",
				"separator": " "
			},
			{ "text": "innenliegend, d.h. eine unzureichende Belichtung"},
			{ "text": "Sanitärobjekte fehlen"},
			{ "text": "für diese Sanitärobjekte sind nur Anschlüsse vorhanden"}
		],
		"kitchenEquipment": [
			{
				"text": "nicht in Wertermittlung enthalten",
				"separator": " "
			},
			{
				"text": "ca. ${Anzahl} lfdm.",
				"separator": " "
			},
			{
				"text": "Baujahr ${Jahr}",
				"separator": " "
			},
			{
				"text": "Einbaugeräte der Marke ${Marke}",
				"separator": " "
			},
			{
				"text": "Der Wert wird mit ${Wert in €} € geschätzt.",
				"separator": " "
			}
		],
		"windows": [
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "tlw. ohne",
				"separator": " "
			},
			{
				"text": "überwiegend",
				"separator": " "
			},
			{
				"text": "Fenster aus Stahl mit",
				"separator": " "
			},
			{
				"text": "Fenster aus Kunststoff mit",
				"separator": " "
			},
			{
				"text": "Fenster aus Aluminium mit",
				"separator": " "
			},
			{
				"text": "Fenster aus Holz",
				"separator": " "
			},
			{
				"text": "Verbundfenster aus Holz und Aluminium",
				"separator": " "
			},
			{
				"text": "Einfachverglasung",
				"separator": " "
			},
			{
				"text": "Doppelverglasung",
				"separator": " "
			},
			{
				"text": "Isolierverglasung",
				"separator": " "
			},
			{
				"text": "Dreifachverglasung",
				"separator": " "
			},
			{
				"text": "tlw. mit innen liegenden Sprossen",
				"separator": " "
			},
			{
				"text": "tlw. mit aufgesetzten Sprossen",
				"separator": " "
			},
			{
				"text": "Schiebeelemente",
				"separator": " "
			},
			{ "text": "Terrassentür" },
			{ "text": "Balkontür" },
			{
				"text": "auch raumhohe Verglasung",
				"separator": " "
			},
			{
				"text": "ohne Rollladen",
				"separator": " "
			},
			{
				"text": "tlw. ohne Rollladen",
				"separator": " "
			},
			{
				"text": "Rollladen aus Kunststoff",
				"separator": " "
			},
			{
				"text": "Rollladen aus Holz",
				"separator": " "
			},
			{
				"text": "Rollladen mit E-Antrieb",
				"separator": " "
			},
			{
				"text": "Rollladen tlw. mit E-Antrieb",
				"separator": " "
			},
			{
				"text": "Fensterbänke innen:",
				"separator": " "
			},
			{
				"text": "Fensterbänke außen:",
				"separator": " "
			},
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "tlw. ohne",
				"separator": " "
			},
			{
				"text": "überwiegend",
				"separator": " "
			},
			{
				"text": "Holz / Werzalit",
				"separator": " "
			},
			{
				"text": "aus Faserzement",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Marmor",
				"separator": " "
			},
			{
				"text": "Betonwerkstein",
				"separator": " "
			},
			{
				"text": "Kunststein",
				"separator": " "
			},
			{
				"text": "Naturstein",
				"separator": " "
			},
			{
				"text": "Terrazzo",
				"separator": " "
			},
			{
				"text": "Aluminium",
				"separator": " "
			},
			{
				"text": "Granit",
				"separator": " "
			},
			{
				"text": "Klinker",
				"separator": " "
			},
			{
				"text": "Mauerwerk mit Verputz",
				"separator": " "
			},
			{
				"text": "Sandstein",
				"separator": " "
			},
			{
				"text": "Dachflächenfenster",
				"separator": " "
			},
			{
				"text": "tlw. Glasbausteine",
				"separator": " "
			}
		],
		"doors": [
			{
				"text": "Eingangstür:",
				"separator": " "
			},
			{
				"text": "Eingangstür und Nebeneingangstür:",
				"separator": " "
			},
			{
				"text": "Wohnungseingangstür:",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "Stahltür mit",
				"separator": " "
			},
			{
				"text": "Holztür mit",
				"separator": " "
			},
			{
				"text": "Kunststofftür mit",
				"separator": " "
			},
			{
				"text": "Metalltür mit",
				"separator": " "
			},
			{
				"text": "Aluminiumtür mit",
				"separator": " "
			},
			{
				"text": "Bronzetür mit",
				"separator": " "
			},
			{
				"text": "Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "mit Oberlicht",
				"separator": " "
			},
			{
				"text": "und Seitenteil",
				"separator": " "
			},
			{
				"text": "erhöhter Einbruchsschutz",
				"separator": " "
			},
			{
				"text": "einfache",
				"separator": " "
			},
			{
				"text": "mittlere",
				"separator": " "
			},
			{
				"text": "hochwertige",
				"separator": " "
			},
			{
				"text": "sehr hochwertige exklusive",
				"separator": " "
			},
			{
				"text": "exklusive",
				"separator": " "
			},
			{
				"text": "Ausführung",
				"separator": " "
			},
			{
				"text": "und Beschläge",
				"separator": " "
			},
			{
				"text": "Zimmertüren:",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "aus Holz",
				"separator": " "
			},
			{
				"text": "Holztüren",
				"separator": " "
			},
			{
				"text": "aus Glas",
				"separator": " "
			},
			{
				"text": "Glastüren",
				"separator": " "
			},
			{
				"text": "aus Kunststoff",
				"separator": " "
			},
			{
				"text": "Kunststofftüren",
				"separator": " "
			},
			{
				"text": "tlw. flächenbündige Ausführung",
				"separator": " "
			},
			{
				"text": "mit Glasfüllungen",
				"separator": " "
			},
			{
				"text": "tlw. mit Glasfüllungen",
				"separator": " "
			},
			{
				"text": "tlw. mit Oberlicht",
				"separator": " "
			},
			{
				"text": "tlw. mit Ornamentglas",
				"separator": " "
			},
			{
				"text": "tlw. mit Bleiverglasung",
				"separator": " "
			},
			{
				"text": "Schiebetüren",
				"separator": " "
			},
			{
				"text": "Flügeltüren",
				"separator": " "
			},
			{
				"text": "tlw. mit Falttüren",
				"separator": " "
			},
			{
				"text": "Stahlzargen",
				"separator": " "
			},
			{
				"text": "Stahleckzargen",
				"separator": " "
			},
			{
				"text": "Holzzargen",
				"separator": " "
			},
			{
				"text": "tlw. ohne Türblätter",
				"separator": " "
			}
		],
		"specialFacility": [
			{
				"text": "auftragsgemäß",
				"separator": " "
			},
			{
				"text": "ohne Ansatz",
				"separator": " "
			},
			{
				"text": "keine vorhanden",
				"separator": " "
			},
			{
				"text": "Einbauküche / Kaffeeküche",
				"separator": " "
			},
			{
				"text": "Einbauschränke",
				"separator": " "
			},
			{
				"text": "Heizkörperverkleidungen",
				"separator": " "
			},
			{
				"text": "offener Kamin",
				"separator": " "
			},
			{
				"text": "Kachelofen",
				"separator": " "
			},
			{
				"text": "Specksteinofen",
				"separator": " "
			},
			{
				"text": "Schwedenofen",
				"separator": " "
			},
			{
				"text": "Grundofen",
				"separator": " "
			},
			{
				"text": "Rauch zieht nicht richtig ab",
				"separator": " "
			},
			{
				"text": "ohne Funktion",
				"separator": " "
			},
			{
				"text": "nicht an Kamin angeschlossen",
				"separator": " "
			},
			{
				"text": "zentrale Staubsaugeranlage",
				"separator": " "
			},
			{
				"text": "Tresor",
				"separator": " "
			},
			{
				"text": "Alarmanlage",
				"separator": " "
			},
			{
				"text": "Blitzschutzanlage",
				"separator": " "
			},
			{
				"text": "Wasserenthärtungsanlage",
				"separator": " "
			},
			{
				"text": "Wasserentkalkungsanlage",
				"separator": " "
			},
			{
				"text": "private Wasserentsorgungsanlage",
				"separator": " "
			},
			{
				"text": "private Wasseraufbereitungsanlage",
				"separator": " "
			},
			{
				"text": "Sauna",
				"separator": " "
			},
			{
				"text": "Sauna mit Dusche (KG)",
				"separator": " "
			},
			{
				"text": "Sauna mit WC (KG)",
				"separator": " "
			},
			{
				"text": "Sauna mit Dusche und WC (KG)",
				"separator": " "
			},
			{
				"text": "Bar im Kellergeschoss",
				"separator": " "
			},
			{
				"text": "Partyraum im Kellergeschoss",
				"separator": " "
			},
			{
				"text": "Müllbeseitigungsanlage",
				"separator": " "
			},
			{
				"text": "Personenaufzug für ${Anzahl Personen} Personen",
				"separator": " "
			},
			{
				"text": "Aufzug für ${Wert in kg} kg",
				"separator": " "
			},
			{
				"text": "Lastenaufzug",
				"separator": " "
			}
		]
	},
	"descriptionAndPartition": {
		"descriptionUnit": {
			"description": {
				"preliminaryRemarks": [
					{
						"text": "Die nachfolgende Ausstattungsbeschreibung der Räume erfolgt zusammenhängend für das zu bewertende Objekt, da es nur geringe Unterschiede gibt und diese nicht als wertrelevant angesehen werden. Nutzflächen wie Kellerräume etc. werden nicht mit aufgeführt.",
						"separator": " "
					},
					{
						"text": "Die nachfolgende Ausstattungsbeschreibung erfolgt für jeden einzelnen Wohnraum des Bewertungsobjekts. Nutzflächen wie Kellerräume etc. werden nicht mit aufgeführt.",
						"separator": " "
					},
					{
						"text": "Die nachfolgende Ausstattungsbeschreibung erfolgt zusammenhängend für die zu bewertende Wohnung, da es nur geringe Unterschiede gibt und diese nicht als wertrelevant angesehen werden.",
						"separator": " "
					},
					{
						"text": "Wegen der nicht durchführbaren Innenbesichtigung kann keine Beschreibung zur Ausstattung und zum Zustand der einzelnen Räume und der Haustechnik erfolgen.",
						"separator": " "
					},
					{
						"text": "Es wird fiktiv ein mittlerer Ausstattungsstandard unterstellt. Die Gesamtnutzungsdauer wird durch den gewählten Ausstattungsstandard und der Objektart geschätzt. Anhand des Wertermittlungsstichtages wird die Restnutzungsdauer ermittelt.",
						"separator": " "
					},
					{
						"text": "Der Sachverständige sieht sich dennoch in der Lage den Verkehrswert zu ermitteln.",
						"separator": " "
					},
					{
						"text": "Die Sachverständige sieht sich dennoch in der Lage den Verkehrswert zu ermitteln.",
						"separator": " "
					},
					{
						"text": "Die vorliegende Ausstattungsbeschreibung erfolgt zusammenhängend für die Beschreibungseinheit: ${Name}",
						"separator": " "
					},
					{
						"text": "Sie wird nachfolgend zu der Beschreibungseinheit mit der Bezeichnung ${Name} zusammengefasst, da es nur geringe Unterschiede gibt und diese nicht als wertrelevant angesehen werden.",
						"separator": " "
					},
					{
						"text": "Außerhalb der in sich abgeschlossenen Wohnung liegende Abstellräume, Kellerräume und Gemeinschaftsräume / -flächen werden nicht aufgeführt.",
						"separator": " "
					},
					{
						"text": "Die nachfolgende Ausstattungsbeschreibung erfolgt für jeden einzelnen Raum der Beschreibungseinheit ${Name}.",
						"separator": " "
					}
				]
			},
			"types": [
				{ "text": "Haus" },
				{ "text": "Einfamilienhaus" },
				{ "text": "Zweifamilienhaus" },
				{ "text": "Dreifamilienhaus" },
				{ "text": "Mehrfamilienhaus" },
				{ "text": "Wohnung" },
				{ "text": "Einliegerwohnung" },
				{ "text": "Laden" },
				{ "text": "Ladenlokal" },
				{ "text": "Büro" },
				{ "text": "Arztpraxis" },
				{ "text": "Praxis" },
				{ "text": "Gaststätte" },
				{ "text": "Restaurant" },
				{ "text": "Souterrainwohnung" },
				{ "text": "Einfamilienhaus mit Einliegerwohnung" },
				{ "text": "Untergeschosswohnung" },
				{ "text": "Erdgeschosswohnung" },
				{ "text": "Obergeschosswohnung" },
				{ "text": "Dachgeschosswohnung" },
				{ "text": "zum Stichtag"}
			]
		}
	},
	"localInspection": {
		"referenceDateOfValuation": [
			{
				"text": "Datum: X",
				"separator": " "
			},
			{
				"text": "(gemäß Vorgabe der Auftraggeber)",
				"separator": " "
			},
			{
				"text": "(gemäß Vorgabe des Auftraggebers)",
				"separator": " "
			},
			{
				"text": "(gemäß Vorgabe der Auftraggeberin)",
				"separator": " "
			},
			{
				"text": "(gemäß Beschluss)",
				"separator": " "
			},
			{
				"text": "(gemäß Beschluss 1. Stichtag)",
				"separator": " "
			},
			{
				"text": "(gemäß Beschluss 2. Stichtag)",
				"separator": " "
			},
			{
				"text": "(gemäß Vorgabe des Gerichts)",
				"separator": " "
			},
			{
				"text": "(Todestag des Erblassers)",
				"separator": " "
			},
			{
				"text": "(voraussichtlicher Zugang des Mieterhöhungsverlangens)",
				"separator": " "
			},
			{
				"text": "(abweichender Qualitätsstichtag)",
				"separator": " "
			},
			{
				"text": "Todestag der Erblasserin",
				"separator": " "
			}
		],
		"rightsBasedOnCadastre": [
			{
				"text": "Baulasten anfragen",
				"separator": " "
			},
			{
				"text": "Baulasten nicht anfragen",
				"separator": " "
			},
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "Eintragung in Abteilung 2",
				"separator": " "
			},
			{
				"text": "Gehrecht",
				"separator": " "
			},
			{
				"text": "Fahrrecht",
				"separator": " "
			},
			{
				"text": "Geh- und Fahrrecht",
				"separator": " "
			},
			{
				"text": "Hochspannungsfreistellungsrecht",
				"separator": " "
			},
			{
				"text": "Leitungsrecht (Gas, Wasser, Strom, Fernwärme)",
				"separator": " "
			},
			{
				"text": "Sanierungsvermerk",
				"separator": " "
			},
			{
				"text": "Denkmalschutz",
				"separator": " "
			},
			{
				"text": "Wohnrecht für ${Name}",
				"separator": " "
			},
			{
				"text": "Geburtstag am ${Geburtsdatum}",
				"separator": " "
			},
			{
				"text": "Das Recht umfasst die Nutzung für",
				"separator": " "
			},
			{
				"text": "das Haus",
				"separator": " "
			},
			{
				"text": "die Wohnung im ${Stockwerk}",
				"separator": " "
			},
			{
				"text": "und",
				"separator": " "
			},
			{
				"text": "die Garage",
				"separator": " "
			},
			{
				"text": "gemeinschaftliche Nutzung des Kellers",
				"separator": " "
			},
			{
				"text": "gemeinschaftliche Nutzung des Gartens",
				"separator": " "
			},
			{
				"text": "ohne Wertansatz",
				"separator": " "
			}
		],
		"appointments": [
			{
				"text": "Datum: ${Datum}",
				"separator": " "
			},
			{
				"text": "Beginn der Ortsbesichtigung: ${Uhrzeit}",
				"separator": " "
			},
			{
				"text": "Ende der Ortsbesichtigung: ${Uhrzeit}",
				"separator": " "
			},
			{
				"text": "1. Termin für die Ortsbesichtigung",
				"separator": " "
			},
			{
				"text": "2. Termin für die Ortsbesichtigung",
				"separator": " "
			},
			{
				"text": "3. Termin für die Ortsbesichtigung",
				"separator": " "
			},
			{
				"text": "Termin wurde nicht eingehalten",
				"separator": " "
			},
			{
				"text": "Termin wurde verschoben",
				"separator": " "
			}
		],
		"participants": [
			{
				"text": "Eigentümer",
				"separator": " "
			},
			{
				"text": "und",
				"separator": " "
			},
			{
				"text": "Eigentümerin",
				"separator": " "
			},
			{
				"text": "der Sachverständige",
				"separator": " "
			},
			{
				"text": "die Sachverständige",
				"separator": " "
			},
			{
				"text": "der Zwangsverwalter",
				"separator": " "
			},
			{
				"text": "die Mieterin",
				"separator": " "
			},
			{
				"text": "der Mieter",
				"separator": " "
			},
			{
				"text": "der Pächter",
				"separator": " "
			},
			{
				"text": "die Pächterin",
				"separator": " "
			},
			{
				"text": "Auftraggeber Herr ${Name}",
				"separator": " "
			},
			{
				"text": "Auftraggeberin Frau ${Name}",
				"separator": " "
			},
			{
				"text": "Betreuer Herr ${Name}",
				"separator": " "
			},
			{
				"text": "Betreuerin Frau ${Name}",
				"separator": " "
			},
			{
				"text": "Prozessbevollmächtigter Herr Rechtsanwalt ${Name}",
				"separator": " "
			},
			{
				"text": "Prozessbevollmächtigte Frau Rechtsanwältin ${Name}",
				"separator": " "
			},
			{
				"text": "(Telefonnummer: ${Telefonnummer})",
				"separator": " "
			},
			{
				"text": "(E-Mail: ${E-Mail})",
				"separator": " "
			}
		],
		"dataBasis": [
			{
				"text": "eine Außenbesichtigung des Objektes",
				"separator": " "
			},
			{
				"text": "eine Innen- und Außenbesichtigung des Objektes",
				"separator": " "
			},
			{
				"text": "eine nachgeholte Innenbesichtigung des Objektes",
				"separator": " "
			}
		],
		"permissionToTakePictures": [
			{
				"text": "ja",
				"separator": " "
			},
			{
				"text": "keine",
				"separator": " "
			},
			{
				"text": "gemäß",
				"separator": " "
			},
			{
				"text": "Angaben des Eigentümers",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümerin",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümer",
				"separator": " "
			},
			{
				"text": "Angaben der Mieterin",
				"separator": " "
			},
			{
				"text": "Angaben des Mieters",
				"separator": " "
			},
			{
				"text": "Auftraggeber",
				"separator": " "
			},
			{
				"text": "Auftraggeberin",
				"separator": " "
			},
			{
				"text": "Betreuer",
				"separator": " "
			},
			{
				"text": "Betreuerin",
				"separator": " "
			},
			{
				"text": "Verwalter",
				"separator": " "
			},
			{
				"text": "Verwalterin",
				"separator": " "
			}
		]
	},
	"buildingDetails": {
		"inheritedWaste": [
			{
				"text": "ja",
				"separator": " "
			},
			{
				"text": "keine",
				"separator": " "
			},
			{
				"text": "gemäß",
				"separator": " "
			},
			{
				"text": "Angaben des Eigentümers",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümerin",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümer",
				"separator": " "
			},
			{
				"text": "Angaben der Mieterin",
				"separator": " "
			},
			{
				"text": "Angaben des Mieters",
				"separator": " "
			},
			{
				"text": "Auftraggeber",
				"separator": " "
			},
			{
				"text": "Auftraggeberin",
				"separator": " "
			},
			{ 
				"text": "Die Untersuchung und Bewertung des Bewertungsobjektes hinsichtlich der Altlasten gehören nicht zum Gutachtenauftrag und werden nicht vorgenommen. In dieser Wertermittlung wird das Bewertungsobjekt als altlastenfrei unterstellt. Diesbezügliche Besonderheiten sind ggf. zusätzlich zu dieser Wertermittlung zu berücksichtigen.",
				"separator": " "
			}
		],
		"buildYear": [
			{
				"text": "Baujahr des Gebäudes: ${Jahr}",
				"separator": " "
			},
			{
				"text": "Baujahr des Anbaus: ${Jahr}",
				"separator": " "
			},
			{
				"text": "Baujahr des zweiten Anbaus: ${Jahr}",
				"separator": " "
			},
			{
				"text": "Baujahr der Garage: ${Jahr}",
				"separator": " "
			},
			{
				"text": "Baujahr der zweiten Garage: ${Jahr}",
				"separator": " "
			},
			{
				"text": "Baujahr der Doppelgarage: ${Jahr}",
				"separator": " "
			},
			{
				"text": "gemäß",
				"separator": " "
			},
			{
				"text": "der Bauakte geschätzt",
				"separator": " "
			},
			{
				"text": "Angaben des Eigentümers",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümerin",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümer",
				"separator": " "
			},
			{
				"text": "Angaben der Mieterin",
				"separator": " "
			},
			{
				"text": "Angaben des Mieters",
				"separator": " "
			},
			{
				"text": "sachverständig",
				"separator": " "
			},
			{
				"text": "Angaben des Auftraggebers",
				"separator": " "
			},
			{
				"text": "Angaben der Auftraggeberin",
				"separator": " "
			},
			{
				"text": "Angaben der Auftraggeber",
				"separator": " "
			},
			{
				"text": "Angaben der Pächterin",
				"separator": " "
			},
			{
				"text": "Angaben des Pächters",
				"separator": " "
			},
			{
				"text": "geschätzt",
				"separator": " "
			},
			{
				"text": "des Verwalters",
				"separator": " "
			},
			{
				"text": "der Verwalterin",
				"separator": " "
			},
			{
				"text": "den Angaben des Zwangsverwalters",
				"separator": " "
			},
			{
				"text": "den Angaben der Zwangsverwalterin",
				"separator": " "
			}
		],
		"energyEfficiency": [
			{
				"text": "Ein Energieausweis liegt vor.",
				"separator": " "
			},
			{
				"text": "Ein Energieausweis liegt nicht vor.",
				"separator": " "
			},
			{
				"text": "Der Energieausweis wurde auf der Grundlage des Energiebedarfs ermittelt.",
				"separator": " "
			},
			{
				"text": "Der Energieausweis wurde auf der Grundlage des Energieverbrauchs ermittelt.",
				"separator": " "
			},
			{
				"text": "Primärenergiebedarf: ${kWh} kWh / (m² * a)",
				"separator": " "
			},
			{
				"text": "Endenergiebedarf: ${kWh} kWh / (m² * a)",
				"separator": " "
			},
			{
				"text": "Energieverbrauchskennwert: ${kWh} kWh / (m² * a)",
				"separator": " "
			},
			{
				"text": "Der Warmwasserverbrauch ist enthalten.",
				"separator": " "
			},
			{
				"text": "Der Warmwasserverbrauch ist nicht enthalten.",
				"separator": " "
			}
		],
		"externalView": [
			{
				"text": "Wärmedämmverbundsystem mit ${cm} cm Wärmedämmung",
				"separator": " "
			},
			{
				"text": " Ausführung im Jahr ${Jahr}",
				"separator": " "
			},
			{
				"text": "ohne Wärmedämmverbundsystem",
				"separator": " "
			},
			{
				"text": "überwiegend",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "unverputzt",
				"separator": " "
			},
			{
				"text": "glatt verputzt",
				"separator": " "
			},
			{
				"text": "glatt verputzt und gestrichen",
				"separator": " "
			},
			{
				"text": "rauh verputzt",
				"separator": " "
			},
			{
				"text": "rauh verputzt und gestrichen",
				"separator": " "
			},
			{
				"text": "Edelputz",
				"separator": " "
			},
			{
				"text": "Rauhputz",
				"separator": " "
			},
			{
				"text": "Kratzputz",
				"separator": " "
			},
			{
				"text": "Kellenputz",
				"separator": " "
			},
			{
				"text": "Scheibenputz",
				"separator": " "
			},
			{
				"text": "Sichtbeton",
				"separator": " "
			},
			{
				"text": "Waschbeton",
				"separator": " "
			},
			{
				"text": "Verblendmauerwerk aus Klinker",
				"separator": " "
			},
			{
				"text": "Sichtmauerwerk",
				"separator": " "
			},
			{
				"text": "Bitumenpappe in Klinkerdekor",
				"separator": " "
			},
			{
				"text": "Verblendung mit Klinker",
				"separator": " "
			},
			{
				"text": "Verblendung mit Kunststeinplatten",
				"separator": " "
			},
			{
				"text": "Verblendung mit Keramik",
				"separator": " "
			},
			{
				"text": "Verblendung mit Naturstein",
				"separator": " "
			},
			{
				"text": "Holzfachwerk mit Ausfachung",
				"separator": " "
			},
			{
				"text": "Holz",
				"separator": " "
			},
			{
				"text": "Natursteinfassade",
				"separator": " "
			},
			{
				"text": "Mosaik",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Bruchsteine",
				"separator": " "
			},
			{
				"text": "Sandstein",
				"separator": " "
			},
			{
				"text": "Tuffstein",
				"separator": " "
			},
			{
				"text": "Kalksandsteinmauerwerk",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Faserzementplatten",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade mit Kunstschieferverkleidung",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Natursteinschiefer",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade mit Holzschindeln",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Betonfertigteile",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Kunststoff",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Marmor",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Werkstein / Kunststein",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Glas",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Blech",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Trapezblech",
				"separator": " "
			},
			{
				"text": "vorgehängte Fassade aus Sandwichplatten",
				"separator": " "
			}
		],
		"plinth": [
			{
				"text": "Wärmedämmverbundsystem mit ${cm} cm Wärmedämmung",
				"separator": " "
			},
			{
				"text": " Ausführung im Jahr ${Jahr}",
				"separator": " "
			},
			{
				"text": "überwiegend",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "unverputzt",
				"separator": " "
			},
			{
				"text": "glatt verputzt",
				"separator": " "
			},
			{
				"text": "glatt verputzt und gestrichen",
				"separator": " "
			},
			{
				"text": "rauh verputzt",
				"separator": " "
			},
			{
				"text": "rauh verputzt und gestrichen",
				"separator": " "
			},
			{
				"text": "Edelputz",
				"separator": " "
			},
			{
				"text": "Rauhputz",
				"separator": " "
			},
			{
				"text": "Kratzputz",
				"separator": " "
			},
			{
				"text": "Kellenputz",
				"separator": " "
			},
			{
				"text": "Scheibenputz",
				"separator": " "
			},
			{
				"text": "Sichtbeton",
				"separator": " "
			},
			{
				"text": "Waschbeton",
				"separator": " "
			},
			{
				"text": "Verblendmauerwerk aus Klinker",
				"separator": " "
			},
			{
				"text": "Sichtmauerwerk",
				"separator": " "
			},
			{
				"text": "Bitumenpappe in Klinkerdekor",
				"separator": " "
			},
			{
				"text": "Verblendung mit Klinker",
				"separator": " "
			},
			{
				"text": "Verblendung mit Kunststeinplatten",
				"separator": " "
			},
			{
				"text": "Verblendung mit Keramik",
				"separator": " "
			},
			{
				"text": "Verblendung mit Naturstein",
				"separator": " "
			},
			{
				"text": "Holzfachwerk mit Ausfachung",
				"separator": " "
			},
			{
				"text": "Holz",
				"separator": " "
			},
			{
				"text": "Natursteinfassade",
				"separator": " "
			},
			{
				"text": "Mosaik",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Bruchsteinen",
				"separator": " "
			},
			{
				"text": "Sandstein",
				"separator": " "
			},
			{
				"text": "Tuffstein",
				"separator": " "
			},
			{
				"text": "Kalksandsteinmauerwerk",
				"separator": " "
			}
		],
		"modernization": [
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "gemäß",
				"separator": " "
			},
			{
				"text": "keine",
				"separator": " "
			},
			{
				"text": "Angaben des Eigentümers",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümerin",
				"separator": " "
			},
			{
				"text": "Angaben der Eigentümer",
				"separator": " "
			},
			{
				"text": "Angaben der Mieterin",
				"separator": " "
			},
			{
				"text": "Angaben des Mieters",
				"separator": " "
			},
			{
				"text": "Angaben des Auftraggebers",
				"separator": " "
			},
			{
				"text": "Angaben der Auftraggeberin",
				"separator": " "
			},
			{
				"text": "Angaben der Auftraggeber",
				"separator": " "
			},
			{
				"text": "Angaben der Pächterin",
				"separator": " "
			},
			{
				"text": "Angaben des Pächters",
				"separator": " "
			},
			{
				"text": "Angaben des Verwalters",
				"separator": " "
			},
			{
				"text": "Angaben der Verwalterin",
				"separator": " "
			},
			{
				"text": "den Angaben des Zwangsverwalters",
				"separator": " "
			},
			{
				"text": "den Angaben der Zwangsverwalterin",
				"separator": " "
			},
			{
				"text": "im Jahr ${Jahr}",
				"separator": " "
			},
			{
				"text": "leicht",
				"separator": " "
			},
			{
				"text": "teilweise",
				"separator": " "
			},
			{
				"text": "überwiegend",
				"separator": " "
			},
			{
				"text": "vollständig",
				"separator": " "
			},
			{
				"text": "renoviert und modernisiert",
				"separator": " "
			},
			{
				"text": "renoviert",
				"separator": " "
			},
			{
				"text": "modernisiert",
				"separator": " "
			},
			{
				"text": "kernsaniert",
				"separator": " "
			},
			{
				"text": "Fenster",
				"separator": " "
			},
			{
				"text": "Heizungsanlage",
				"separator": " "
			},
			{
				"text": "Bad",
				"separator": " "
			},
			{
				"text": "Fassade",
				"separator": " "
			},
			{
				"text": "Bodenbeläge",
				"separator": " "
			},
			{
				"text": "Türen",
				"separator": " "
			},
			{
				"text": "Dämmung der obersten Decke",
				"separator": " "
			},
			{
				"text": "Elektroinstallation",
				"separator": " "
			},
			{
				"text": "Sanitärinstallation",
				"separator": " "
			},
			{
				"text": "Treppenhaus",
				"separator": " "
			},
			{
				"text": "Dacheindeckung",
				"separator": " "
			},
			{
				"text": "inkl. Wärmedämmung",
				"separator": " "
			},
			{
				"text": "Dach komplett inkl. der tragenden Konstruktion",
				"separator": " "
			}
		],
		"typeOfBuilding": [
			{
				"text": "Teileigentum",
				"separator": " "
			},
			{
				"text": "Wohnungseigentum",
				"separator": " "
			},
			{
				"text": "als exklusive",
				"separator": " "
			},
			{
				"text": "Penthousewohnung",
				"separator": " "
			},
			{
				"text": "Eigentumswohnung",
				"separator": " "
			},
			{
				"text": "in einem(r)",
				"separator": " "
			},
			{
				"text": "freistehendes",
				"separator": " "
			},
			{
				"text": "einseitig angebaut",
				"separator": " "
			},
			{
				"text": "zweiseitig angebaut",
				"separator": " "
			},
			{
				"text": "mehrseitig angebaut",
				"separator": " "
			},
			{
				"text": "Doppelhaushälfte",
				"separator": " "
			},
			{
				"text": "X geschossiges",
				"separator": " "
			},
			{
				"text": "Einfamilienwohnhaus",
				"separator": " "
			},
			{
				"text": "Zweifamilienwohnhaus",
				"separator": " "
			},
			{
				"text": "Dreifamilienwohnhaus",
				"separator": " "
			},
			{
				"text": "Mehrfamilienwohnhaus",
				"separator": " "
			},
			{
				"text": "(mit ${Anzahl Einheiten} Einheiten)",
				"separator": " "
			},
			{
				"text": "gemischt genutztes Gebäude",
				"separator": " "
			},
			{
				"text": "Wohngebäude",
				"separator": " "
			},
			{
				"text": "Wohn-Bürogebäude",
				"separator": " "
			},
			{
				"text": "Verwaltungsgebäude",
				"separator": " "
			},
			{
				"text": "Wohn-Geschäftshaus",
				"separator": " "
			},
			{
				"text": "Bürogebäude",
				"separator": " "
			},
			{
				"text": "Laden mit Wohnung",
				"separator": " "
			},
			{
				"text": "Gaststätte mit Wohnung",
				"separator": " "
			},
			{
				"text": "Arztpraxis mit Wohnung",
				"separator": " "
			},
			{
				"text": "Das Gebäude ist unterkellert.",
				"separator": " "
			},
			{
				"text": "Das Gebäude ist nicht unterkellert.",
				"separator": " "
			},
			{
				"text": "Das Gebäude ist teilunterkellert.",
				"separator": " "
			},
			{
				"text": "Das Dachgeschoss ist ausgebaut.",
				"separator": " "
			},
			{
				"text": "Das Dachgeschoss ist nicht ausgebaut.",
				"separator": " "
			},
			{
				"text": "Das Dachgeschoss ist teilweise ausgebaut.",
				"separator": " "
			},
			{
				"text": "mit Anbau",
				"separator": " "
			},
			{
				"text": "mit mehreren Anbauten",
				"separator": " "
			},
			{
				"text": "mit einer Einliegerwohnung",
				"separator": " "
			},
			{
				"text": "mit einer Garage",
				"separator": " "
			},
			{
				"text": "mit einer Doppelgarage",
				"separator": " "
			},
			{
				"text": "mit zwei Einzelgaragen",
				"separator": " "
			},
			{
				"text": "als Bauchwichgarage",
				"separator": " "
			},
			{
				"text": "mit einem Carport",
				"separator": " "
			},
			{
				"text": "und einem Schuppen",
				"separator": " "
			},
			{
				"text": "mit Geräteschuppen",
				"separator": " "
			},
			{
				"text": "überwiegend zu Wohnzwecken genutzt",
				"separator": " "
			},
			{
				"text": "überwiegend gewerblich genutzt",
				"separator": " "
			},
			{
				"text": "der gewerbliche Anteil beträgt rd.",
				"separator": " "
			},
			{
				"text": "${Wert in %} % bezogen auf die Wohnfläche",
				"separator": " "
			},
			{
				"text": "${Wert in %} % bezogen auf die Mietwerte",
				"separator": " "
			}
		],
		"optionsOfExtension": [
			{
				"text": "Das Dachgeschoss",
				"separator": " "
			},
			{
				"text": "Die Scheune",
				"separator": " "
			},
			{
				"text": "Der Dachraum",
				"separator": " "
			},
			{
				"text": "Das Dachgeschoss und der Dachraum",
				"separator": " "
			},
			{
				"text": "Der Anbau",
				"separator": " "
			},
			{
				"text": "Den Anbau",
				"separator": " "
			},
			{
				"text": "ist ausbaufähig.",
				"separator": " "
			},
			{
				"text": "ist nicht ausbaufähig.",
				"separator": " "
			},
			{
				"text": "sind nicht ausbaufähig.",
				"separator": " "
			},
			{
				"text": "auszubauen erscheint unwirtschaftlich.",
				"separator": " "
			},
			{
				"text": "ist tlw. ausbaufähig.",
				"separator": " "
			},
			{
				"text": "sind tlw. ausbaufähig.",
				"separator": " "
			},
			{
				"text": "Eine diesbezügliche Baugenehmigung liegt vor.",
				"separator": " "
			},
			{
				"text": "Eine diesbezügliche Baugenehmigung liegt nicht vor.",
				"separator": " "
			}
		],
		"garage": {
			"externalView": [
				{
					"text": "überwiegend",
					"separator": " "
				},
				{
					"text": "tlw.",
					"separator": " "
				},
				{
					"text": "unverputzt",
					"separator": " "
				},
				{
					"text": "glatt verputzt",
					"separator": " "
				},
				{
					"text": "glatt verputzt und gestrichen",
					"separator": " "
				},
				{
					"text": "rauh verputzt",
					"separator": " "
				},
				{
					"text": "rauh verputzt und gestrichen",
					"separator": " "
				},
				{
					"text": "Edelputz",
					"separator": " "
				},
				{
					"text": "Rauhputz",
					"separator": " "
				},
				{
					"text": "Kratzputz",
					"separator": " "
				},
				{
					"text": "Kellenputz",
					"separator": " "
				},
				{
					"text": "Scheibenputz",
					"separator": " "
				},
				{
					"text": "Sichtbeton",
					"separator": " "
				},
				{
					"text": "Waschbeton",
					"separator": " "
				},
				{
					"text": "Verblendmauerwerk aus Klinker",
					"separator": " "
				},
				{
					"text": "Sichtmauerwerk",
					"separator": " "
				},
				{
					"text": "Bitumenpappe in Klinkerdekor",
					"separator": " "
				},
				{
					"text": "Verblendung mit Klinker",
					"separator": " "
				},
				{
					"text": "Verblendung mit Kunststeinplatten",
					"separator": " "
				},
				{
					"text": "Verblendung mit Keramik",
					"separator": " "
				},
				{
					"text": "Verblendung mit Naturstein",
					"separator": " "
				},
				{
					"text": "Holzfachwerk mit Ausfachung",
					"separator": " "
				},
				{
					"text": "Holz",
					"separator": " "
				},
				{
					"text": "Natursteinfassade",
					"separator": " "
				},
				{
					"text": "Mosaik",
					"separator": " "
				},
				{
					"text": "Fliesen",
					"separator": " "
				},
				{
					"text": "Bruchsteinen",
					"separator": " "
				},
				{
					"text": "Sandstein",
					"separator": " "
				},
				{
					"text": "Tuffstein",
					"separator": " "
				},
				{
					"text": "Kalksandsteinmauerwerk",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Faserzementplatten",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade mit Kunstschieferverkleidung",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Natursteinschiefer",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade mit Holzschindeln",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Betonfertigteile",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Kunststoff",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Marmor",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Werkstein / Kunststein",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Glas",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Blech",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Trapezblech",
					"separator": " "
				},
				{
					"text": "vorgehängte Fassade aus Sandwichplatten",
					"separator": " "
				}
			],
			"plinth": [
				{
					"text": "überwiegend",
					"separator": " "
				},
				{
					"text": "tlw.",
					"separator": " "
				},
				{
					"text": "unverputzt",
					"separator": " "
				},
				{
					"text": "glatt verputzt",
					"separator": " "
				},
				{
					"text": "glatt verputzt und gestrichen",
					"separator": " "
				},
				{
					"text": "rauh verputzt",
					"separator": " "
				},
				{
					"text": "rauh verputzt und gestrichen",
					"separator": " "
				},
				{
					"text": "Edelputz",
					"separator": " "
				},
				{
					"text": "Rauhputz",
					"separator": " "
				},
				{
					"text": "Kratzputz",
					"separator": " "
				},
				{
					"text": "Kellenputz",
					"separator": " "
				},
				{
					"text": "Scheibenputz",
					"separator": " "
				},
				{
					"text": "Sichtbeton",
					"separator": " "
				},
				{
					"text": "Waschbeton",
					"separator": " "
				},
				{
					"text": "Verblendmauerwerk aus Klinker",
					"separator": " "
				},
				{
					"text": "Sichtmauerwerk",
					"separator": " "
				},
				{
					"text": "Bitumenpappe in Klinkerdekor",
					"separator": " "
				},
				{
					"text": "Verblendung mit Klinker",
					"separator": " "
				},
				{
					"text": "Verblendung mit Kunststeinplatten",
					"separator": " "
				},
				{
					"text": "Verblendung mit Keramik",
					"separator": " "
				},
				{
					"text": "Verblendung mit Naturstein",
					"separator": " "
				},
				{
					"text": "Holzfachwerk mit Ausfachung",
					"separator": " "
				},
				{
					"text": "Holz",
					"separator": " "
				},
				{
					"text": "Natursteinfassade",
					"separator": " "
				},
				{
					"text": "Mosaik",
					"separator": " "
				},
				{
					"text": "Fliesen",
					"separator": " "
				},
				{
					"text": "Bruchsteinen",
					"separator": " "
				},
				{
					"text": "Sandstein",
					"separator": " "
				},
				{
					"text": "Tuffstein",
					"separator": " "
				},
				{
					"text": "Kalksandsteinmauerwerk",
					"separator": " "
				}
			]
		}
	},
	"buildingConstruction": {

		"foundation": [
			{
				"text": "Bruchstein (fiktive Annahme)",
				"separator": " "
			},
			{
				"text": "Streifenfundament (fiktive Annahme)",
				"separator": " "
			},
			{
				"text": "Streifenfundament aus Beton",
				"separator": " "
			},
			{
				"text": "tragende Bodenplatte (biegesteife Bodenplatte)",
				"separator": " "
			},
			{
				"text": "Blockfundament",
				"separator": " "
			}
		],
		"typeOfConstruction": [
			{
				"text": "massiv",
				"separator": " "
			},
			{
				"text": "Skelettbau",
				"separator": " "
			},
			{
				"text": "(Stahlkonstruktion)",
				"separator": " "
			},
			{
				"text": "(Stahlbeton)",
				"separator": " "
			},
			{
				"text": "(Fachwerk)",
				"separator": " "
			},
			{
				"text": "Fachwerk",
				"separator": " "
			},
			{
				"text": "(Eiche)",
				"separator": " "
			},
			{
				"text": "(Nadelholz)",
				"separator": " "
			},
			{
				"text": "Tafelbauweise",
				"separator": " "
			},
			{
				"text": "Fertigbauweise",
				"separator": " "
			},
			{
				"text": "(massiv)",
				"separator": " "
			},
			{
				"text": "Fertighaushersteller",
				"separator": " "
			},
			{
				"text": "Fertighaustyp",
				"separator": " "
			},
			{
				"text": "Hinweis: Eine PCB-Belastung ist möglich; diesbezüglich wird vorgeschlagen, einen entsprechende(n) Sachverständige(n) zu beauftragen und das Ergebnis zusätzlich zu berücksichtigen.",
				"separator": " "
			},
			{
				"text": "Hinweis: Eine Formaldehyd-Belastung ist möglich; diesbezüglich wird vorgeschlagen,  einen entsprechende(n) Sachverständige(n) zu beauftragen und das Ergebnis zusätzlich zu berücksichtigen.",
				"separator": " "
			}
		],
		"insideWalls": [
			{
				"text": "tragende Innenwände",
				"separator": " "
			},
			{
				"text": "nichttragende Innenwände",
				"separator": " "
			},
			{
				"text": "Mauerwerk",
				"separator": " "
			},
			{
				"text": "Bruchsteinmauerwerk",
				"separator": " "
			},
			{
				"text": "Hohlblocksteine",
				"separator": " "
			},
			{
				"text": "Kalksandstein",
				"separator": " "
			},
			{
				"text": "Ziegelstein",
				"separator": " "
			},
			{
				"text": "Ortbeton",
				"separator": " "
			},
			{
				"text": "Betonfertigteile",
				"separator": " "
			},
			{
				"text": "Ständerwände",
				"separator": " "
			}
		],
		"outerWalls": [
			{
				"text": "Mauerwerk",
				"separator": " "
			},
			{
				"text": "Bruchsteinmauerwerk",
				"separator": " "
			},
			{
				"text": "Hohlblocksteine",
				"separator": " "
			},
			{
				"text": "Kalksandstein",
				"separator": " "
			},
			{
				"text": "Ziegelstein",
				"separator": " "
			},
			{
				"text": "Ortbeton",
				"separator": " "
			},
			{
				"text": "Betonfertigteile",
				"separator": " "
			},
			{
				"text": "Wärmedämmstein",
				"separator": " "
			},
			{
				"text": "Gasbetonstein",
				"separator": " "
			},
			{
				"text": "Liapor",
				"separator": " "
			},
			{
				"text": "Bisotherm",
				"separator": " "
			},
			{
				"text": "Poroton",
				"separator": " "
			},
			{
				"text": "Klimaleichtblock (KLB)",
				"separator": " "
			}
		],
		"staircases": {
			"generalImpression": [
				{
					"text": "gepflegt",
					"separator": " "
				},
				{
					"text": "sehr gepflegt",
					"separator": " "
				},
				{
					"text": "dem Baujahr entsprechend",
					"separator": " "
				},
				{
					"text": "repräsentativ",
					"separator": " "
				},
				{
					"text": "geringfügig vernachlässigt",
					"separator": " "
				},
				{
					"text": "vernachlässigt",
					"separator": " "
				},
				{
					"text": "stark vernachlässigt",
					"separator": " "
				},
				{
					"text": "erneuerungsbedürftig",
					"separator": " "
				},
				{
					"text": "sanierungsbedürftig",
					"separator": " "
				}
			],
			"storeyStaircases": [
				{
					"text": "ohne",
					"separator": " "
				},
				{
					"text": "Bautreppe",
					"separator": " "
				},
				{
					"text": "Treppe aus",
					"separator": " "
				},
				{
					"text": "Holztreppe",
					"separator": " "
				},
				{
					"text": "Stahltreppe",
					"separator": " "
				},
				{
					"text": "Stahlbetontreppe",
					"separator": " "
				},
				{
					"text": "Stahl / Holzkonstruktion",
					"separator": " "
				},
				{
					"text": "Natursteintreppe",
					"separator": " "
				},
				{
					"text": "Waschbetonplatten",
					"separator": " "
				},
				{
					"text": "Terrazzo",
					"separator": " "
				},
				{
					"text": "Belag:",
					"separator": " "
				},
				{
					"text": "ohne",
					"separator": " "
				},
				{
					"text": "Anstrich",
					"separator": " "
				},
				{
					"text": "Fliesen",
					"separator": " "
				},
				{
					"text": "Granit",
					"separator": " "
				},
				{
					"text": "Marmor",
					"separator": " "
				},
				{
					"text": "Naturstein",
					"separator": " "
				},
				{
					"text": "Bruchsteine",
					"separator": " "
				},
				{
					"text": "Schiefer",
					"separator": " "
				},
				{
					"text": "Kunststein",
					"separator": " "
				},
				{
					"text": "Werkstein",
					"separator": " "
				},
				{
					"text": "Holz",
					"separator": " "
				},
				{
					"text": "(Buche)",
					"separator": " "
				},
				{
					"text": "(Eiche)",
					"separator": " "
				},
				{
					"text": "PVC",
					"separator": " "
				},
				{
					"text": "Teppich",
					"separator": " "
				},
				{
					"text": "Laminat",
					"separator": " "
				},
				{
					"text": "Parkett",
					"separator": " "
				},
				{
					"text": "Geländer:",
					"separator": " "
				},
				{
					"text": "ohne",
					"separator": " "
				},
				{
					"text": "einfaches",
					"separator": " "
				},
				{
					"text": "besseres",
					"separator": " "
				},
				{
					"text": "exklusives",
					"separator": " "
				},
				{
					"text": "Holzgeländer",
					"separator": " "
				},
				{
					"text": "Stahlgeländer",
					"separator": " "
				},
				{
					"text": "Edelstahlgeländer",
					"separator": " "
				},
				{
					"text": "Stahl / Glasgeländer",
					"separator": " "
				},
				{
					"text": "künstlerisch gestalteter Handlauf",
					"separator": " "
				},
				{
					"text": "einfacher Handlauf",
					"separator": " "
				},
				{
					"text": "hochwertiger Handlauf",
					"separator": " "
				},
				{
					"text": "künstlerisch gestaltetes Geländer",
					"separator": " "
				}
			]
		},
		"doorOfHouseEntrance": [
			{
				"text": "jeweils eine",
				"separator": " "
			},
			{
				"text": "alte",
				"separator": " "
			},
			{
				"text": "(zweiflügelig)",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Stahl",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Holz",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Aluminium",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Kunststoff",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Bronze",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Stahl mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Holz mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Aluminium mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Kunststoff mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Bronze mit Lichtausschnitt",
				"separator": " "
			},
			{ "text":  "mit Seitenteil" }
		],
		"doorOfSideEntrance": [
			{
				"text": "jeweils eine",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Stahl",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Holz",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Aluminium",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Kunststoff",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Bronze",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Stahl mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Holz mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Aluminium mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Kunststoff mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "Eingangstür aus Bronze mit Lichtausschnitt",
				"separator": " "
			},
			{
				"text": "mit Glaseinsatz",
				"separator": " "
			},
			{
				"text": "mit Seitenteil",
				"separator": " "
			}
		],
		"roofConstruction": {
			"possibilityToExamine": [
				{
					"text": "einsehbar",
					"separator": " "
				},
				{
					"text": "tlw. einsehbar",
					"separator": " "
				},
				{
					"text": "Konnte nicht eingesehen werden",
					"separator": " "
				},
				{
					"text": "Es wird ein Sicherheitsabschlag von ${Sicherheitsabschlag}% vorgenommen.",
					"separator": " "
				}
			],
			"construction": [
				{
					"text": "Holzkonstruktion",
					"separator": " "
				},
				{
					"text": "Binderkonstruktion",
					"separator": " "
				},
				{
					"text": "Fachwerkbinder",
					"separator": " "
				},
				{
					"text": "Stahlbinder",
					"separator": " "
				},
				{
					"text": "Stahlbetonbinder",
					"separator": " "
				},
				{
					"text": "Pfetten aus Holz",
					"separator": " "
				},
				{
					"text": "Träger aus Stahl",
					"separator": " "
				},
				{
					"text": "Träger aus Holz",
					"separator": " "
				},
				{
					"text": "mit Holzverschalung",
					"separator": " "
				},
				{
					"text": "ohne Aufbauten",
					"separator": " "
				},
				{
					"text": "mit Aufbauten",
					"separator": " "
				},
				{
					"text": "mit Gaube(n)",
					"separator": " "
				},
				{
					"text": "mit verzinkten / Kunstoff Dachflächenfenster(n)",
					"separator": " "
				},
				{
					"text": "Giebelfenster aus Holz",
					"separator": " "
				},
				{
					"text": "Giebelfenster aus Stahl",
					"separator": " "
				},
				{
					"text": "Giebelfenster aus Kunststoff",
					"separator": " "
				},
				{
					"text": "Giebelfenster aus Aluminium",
					"separator": " "
				},
				{
					"text": "sichtbare Sparren",
					"separator": " "
				},
				{
					"text": "sichtbare Sparren und Pfetten",
					"separator": " "
				},
				{
					"text": "mit Profilholz",
					"separator": " "
				},
				{
					"text": "mit Tapete",
					"separator": " "
				},
				{
					"text": "mit Reibeputz",
					"separator": " "
				},
				{
					"text": "mit Strukturputz",
					"separator": " "
				},
				{
					"text": "ohne Folie",
					"separator": " "
				},
				{
					"text": "mit Folie",
					"separator": " "
				},
				{
					"text": "mit Konterlattung",
					"separator": " "
				}
			],
			"shape": [
				{
					"text": "Satteldach",
					"separator": " "
				},
				{
					"text": "Walmdach",
					"separator": " "
				},
				{
					"text": "Krüppelwalmdach",
					"separator": " "
				},
				{
					"text": "einseitiges Krüppelwalmdach",
					"separator": " "
				},
				{
					"text": "Pultdach",
					"separator": " "
				},
				{
					"text": "Flachdach",
					"separator": " "
				},
				{
					"text": "als Kaltdach",
					"separator": " "
				},
				{
					"text": "als Warmdach",
					"separator": " "
				},
				{
					"text": "Mansardendach",
					"separator": " "
				},
				{
					"text": "Zeltdach",
					"separator": " "
				}
			],
			"covering": [
				{
					"text": "Dachziegel (Ton)",
					"separator": " "
				},
				{
					"text": "Betondachstein",
					"separator": " "
				},
				{
					"text": "Biberschwanzeindeckung",
					"separator": " "
				},
				{
					"text": "Faserzementplatten",
					"separator": " "
				},
				{
					"text": "Bitumendachbahnen",
					"separator": " "
				},
				{
					"text": "Dachpappschindeln",
					"separator": " "
				},
				{
					"text": "Dachpappe",
					"separator": " "
				},
				{
					"text": "Trapezblech",
					"separator": " "
				},
				{
					"text": "Wellplatten aus glasfaserverstärktem Polyesterharz",
					"separator": " "
				},
				{
					"text": "Falzblech",
					"separator": " "
				},
				{
					"text": "Zinkdoppelstehfalz",
					"separator": " "
				},
				{
					"text": "Wellplatten",
					"separator": " "
				},
				{
					"text": "Gasbetonplatten",
					"separator": " "
				},
				{
					"text": "Sandwichelemente",
					"separator": " "
				},
				{
					"text": "Dachflächenlichtplatten",
					"separator": " "
				},
				{
					"text": "Naturschiefer",
					"separator": " "
				},
				{
					"text": "Kunstschiefer",
					"separator": " "
				},
				{
					"text": "Reet",
					"separator": " "
				},
				{
					"text": "Schindeln (Holz)",
					"separator": " "
				},
				{
					"text": "Schindeln (Ton)",
					"separator": " "
				},
				{
					"text": "Schindeln (Bitumen)",
					"separator": " "
				},
				{
					"text": "Trapezsandwichplatten",
					"separator": " "
				},
				{
					"text": "Trapezblech",
					"separator": " "
				},
				{
					"text": "Wellblech",
					"separator": " "
				},
				{
					"text": "Bleiblech / Zinkblech",
					"separator": " "
				},
				{
					"text": "Aluminium",
					"separator": " "
				},
				{
					"text": "Kupferfalzblech",
					"separator": " "
				},
				{
					"text": "Dachflächen ungedämmt",
					"separator": " "
				},
				{
					"text": "Dachflächen gedämmt",
					"separator": " "
				},
				{
					"text": "oberste Decke ungedämmt",
					"separator": " "
				},
				{
					"text": "oberste Decke gedämmt",
					"separator": " "
				},
				{
					"text": "tlw. unzureichend gedämmt",
					"separator": " "
				},
				{
					"text": "Dachraum begehbar und zugänglich (besitzt kein Ausbaupotenzial)",
					"separator": " "
				},
				{
					"text": "Dachraum begehbar und zugänglich (besitzt Ausbaupotenzial)",
					"separator": " "
				},
				{
					"text": "Dachraum nicht begehbar und zugänglich (besitzt kein Ausbaupotenzial)",
					"separator": " "
				},
				{
					"text": "Dachraum nicht begehbar und zugänglich (besitzt Ausbaupotenzial)",
					"separator": " "
				},
				{
					"text": "gedämmt lt. Aussage des Eigentümers / Schuldners",
					"separator": " "
				},
				{
					"text": "Dachrinnen und Regenfallrohre aus Kunststoff",
					"separator": " "
				},
				{
					"text": "Dachrinnen und Regenfallrohre aus Zinkblech",
					"separator": " "
				},
				{
					"text": "Dachrinnen und Regenfallrohre aus Kupfer",
					"separator": " "
				},
				{
					"text": "mit einfachem Wasserspeier",
					"separator": " "
				},
				{
					"text": "tlw. / ohne Anschluss an die Entwässerungsleitungen",
					"separator": " "
				}
			],
			"insulationTopmostCeiling": [
				{
					"text": "nicht vorhanden",
					"separator": " "
				},
				{
					"text": "vorhanden",
					"separator": " "
				},
				{
					"text": "Stärke der Dämmung: ${Stärke}",
					"separator": " "
				},
				{
					"text": "nach heutigen Gesichtspunkten nicht ausreichend",
					"separator": " "
				}
			],
			"insulationBetweenRafters": [
				{
					"text": "nicht vorhanden",
					"separator": " "
				},
				{
					"text": "vorhanden",
					"separator": " "
				},
				{
					"text": "Stärke der Dämmung ${Stärke}",
					"separator": " "
				},
				{
					"text": "nach heutigen Gesichtspunkten nicht ausreichend",
					"separator": " "
				}
			],
			"insulationBetweenRaftersLivingArea": [
				{
					"text": "nicht vorhanden",
					"separator": " "
				},
				{
					"text": "vorhanden",
					"separator": " "
				},
				{
					"text": "Stärke der Dämmung ${Stärke}",
					"separator": " "
				},
				{
					"text": "nach heutigen Gesichtspunkten nicht ausreichend",
					"separator": " "
				}
			],
			"damage": [
				{
					"text": "Brandschaden",
					"separator": " "
				},
				{
					"text": "Holzschädlinge",
					"separator": " "
				},
				{
					"text": "tlw. Holzwurmbefall",
					"separator": " "
				},
				{
					"text": "starker Holzwurmbefall",
					"separator": " "
				},
				{
					"text": "Standsicherheit kann gefährdet sein",
					"separator": " "
				},
				{
					"text": "Es wird empfohlen, eine(n) Bauschadenssachverständige(n) hinzu zu ziehen. Diesbezügliche Besonderheiten sind zusätzlich zu berücksichtigen.",
					"separator": " "
				},
				{
					"text": "Dacheindeckung",
					"separator": " "
				},
				{
					"text": "Dachkonstruktion",
					"separator": " "
				},
				{
					"text": "mittelfristig",
					"separator": " "
				},
				{
					"text": "erneuerungsbedürftig",
					"separator": " "
				},
				{
					"text": "keine ersichtlich",
					"separator": " "
				},
				{
					"text": "Kamineinfassung",
					"separator": " "
				},
				{
					"text": "erneuern",
					"separator": " "
				},
				{
					"text": "starke Durchbiegung der Dachfläche",
					"separator": " "
				},
				{
					"text": "Sturmschäden",
					"separator": " "
				},
				{ "text": "ohne" },
				{ "text": "keine wesentliche ersichtlich" }
			]
		}
	},
	"generalTechnicalEquipment": {
		"waterInstallations": [
			{
				"text": "zentrale Wasserversorgung über Anschluss an das öffentliche Trinkwassernetz",
				"separator": " "
			},
			{
				"text": "Trinkwassergewinnung durch eigenen Brunnen",
				"separator": " "
			},
			{
				"text": "Trinkwassergewinnung und Aufbereitung durch eigenen Brunnen",
				"separator": " "
			},
			{
				"text": "Trinkwassergewinnung durch eigenen Brunnen und eigene Aufbereitungsanleitung",
				"separator": " "
			},
			{
				"text": "ergänzt durch Regenwassernutzungsanlage",
				"separator": " "
			},
			{
				"text": "ergänzt durch Regenwassernutzungsanlage mit Erdtank",
				"separator": " "
			},
			{
				"text": "ergänzt durch Regenwassernutzungsanlage mit Kellertank",
				"separator": " "
			},
			{
				"text": "ergänzt durch Grundwassernutzungsanlage",
				"separator": " "
			},
			{
				"text": "ergänzt durch Grundwassernutzungsanlage für WC-Spülung",
				"separator": " "
			},
			{
				"text": "Wasserleitungen aus",
				"separator": " "
			},
			{
				"text": "Kupferrohr",
				"separator": " "
			},
			{
				"text": "Kunststoffrohr",
				"separator": " "
			},
			{
				"text": "Stahlrohr",
				"separator": " "
			},
			{
				"text": "Edelstahlrohr",
				"separator": " "
			},
			{
				"text": "tlw. mit Rohrbegleitheizung",
				"separator": " "
			},
			{
				"text": "mit Frostschutzwächter",
				"separator": " "
			},
			{
				"text": "und thermostatischer Regelung",
				"separator": " "
			},
			{
				"text": "Ausführung als Vorwandinstallation",
				"separator": " "
			},
			{
				"text": "Ausführung überwiegend als Vorwandinstallation",
				"separator": " "
			},
			{
				"text": "Druckerhöhunganlage",
				"separator": " "
			},
			{
				"text": "Wasseraufbereitung durch",
				"separator": " "
			},
			{
				"text": "Wasserenthärtungsanalage",
				"separator": " "
			},
			{
				"text": "Nitratentfernungsanlage",
				"separator": " "
			},
			{
				"text": "Wasserenthärtungsanlage und Nitratentfernungsanlage",
				"separator": " "
			},
			{
				"text": "Wärmeverteilungs- und Warmwasserleitungen",
				"separator": " "
			},
			{
				"text": "tlw. in unbeheizten Räumen, ca.",
				"separator": " "
			},
			{
				"text": "ausschließlich in beheizten Räumen, ca.",
				"separator": " "
			},
			{
				"text": "X lfdm. ungedämmte Rohrleitungen",
				"separator": " "
			},
			{
				"text": "nicht zugänglich",
				"separator": " "
			}
		],
		"sewageInstallations": [
			{
				"text": "Es wird ohne Überprüfung unterstellt, dass die Ableitung in das kommunale Abwasserkanalnetz gemäß den Bestimmungen der Gemeinde erfolgt.",
				"separator": " "
			},
			{
				"text": "Ableitung in kommunales Abwasserkanalnetz",
				"separator": " "
			},
			{
				"text": "Ableitung in private Klärgrube",
				"separator": " "
			},
			{
				"text": "Ableitung in private Kleinkläranlage",
				"separator": " "
			},
			{
				"text": "Abflussrohe (Sammel- und Fallleitungen) aus",
				"separator": " "
			},
			{
				"text": "Kunststoff",
				"separator": " "
			},
			{
				"text": "Gusseisen",
				"separator": " "
			},
			{
				"text": "Faserzement",
				"separator": " "
			},
			{
				"text": "Stahl mit Korrosionsschutz",
				"separator": " "
			},
			{
				"text": "Kellerentwässerungspumpe",
				"separator": " "
			},
			{
				"text": "automatische Kellerentwässerungspumpe",
				"separator": " "
			},
			{
				"text": "Pumpsumpf mit Kellerentwässerungspumpe",
				"separator": " "
			},
			{
				"text": "Pumpsumpf mit automatischer Kellerentwässerungspumpe",
				"separator": " "
			},
			{
				"text": "Rückstauschutz:",
				"separator": " "
			},
			{
				"text": "Rückstauklappe",
				"separator": " "
			},
			{
				"text": "mit zusätzlicher Handabsperrung",
				"separator": " "
			},
			{
				"text": "Überschwemmungsschutz",
				"separator": " "
			},
			{
				"text": "Ausführung als Trennsystem",
				"separator": " "
			},
			{
				"text": "Abwasserhebeanlage",
				"separator": " "
			},
			{
				"text": "im KG",
				"separator": " "
			},
			{
				"text": "zur Zeit ist die Kanalleitung verstopft",
				"separator": " "
			}
		],
		"electricalInstallation": [
			{
				"text": "technisch überaltert",
				"separator": " "
			},
			{
				"text": "ohne weitere Beschreibung, es wird eine Neuinstallation in Ansatz gebracht",
				"separator": " "
			},
			{
				"text": "je Raum ein bis zwei Lichtauslässe",
				"separator": " "
			},
			{
				"text": "je Raum ein bis mehrere Steckdosen",
				"separator": " "
			},
			{
				"text": "Klingelanlage",
				"separator": " "
			},
			{
				"text": "Telefonanschluss",
				"separator": " "
			},
			{
				"text": "SAT-Anlage",
				"separator": " "
			},
			{
				"text": "einfache",
				"separator": " "
			},
			{
				"text": "mittlere",
				"separator": " "
			},
			{
				"text": "hochwertige",
				"separator": " "
			},
			{
				"text": "sehr hochwertige",
				"separator": " "
			},
			{
				"text": "exklusive",
				"separator": " "
			},
			{
				"text": "Ausstattung",
				"separator": " "
			},
			{
				"text": "tlw. auf Putz verlegt",
				"separator": " "
			},
			{
				"text": "auf Putz verlegt",
				"separator": " "
			},
			{
				"text": "im KG auf Putz verlegt",
				"separator": " "
			},
			{
				"text": "je Raum",
				"separator": " "
			},
			{
				"text": "ein",
				"separator": " "
			},
			{
				"text": "ein bis zwei",
				"separator": " "
			},
			{
				"text": "mehrere",
				"separator": " "
			},
			{
				"text": "Lichtauslass",
				"separator": " "
			},
			{
				"text": "Lichtauslässe",
				"separator": " "
			},
			{
				"text": "Steckdose",
				"separator": " "
			},
			{
				"text": "Steckdosen",
				"separator": " "
			},
			{
				"text": "Beleuchtungskörper",
				"separator": " "
			},
			{
				"text": "Fernsehanschluss",
				"separator": " "
			},
			{
				"text": "mehrere Fernsehanschlüsse",
				"separator": " "
			},
			{
				"text": "Fernsehkabelanschluss",
				"separator": " "
			},
			{
				"text": "Türöffner",
				"separator": " "
			},
			{
				"text": "Blitzschutzanlage",
				"separator": " "
			},
			{
				"text": "Starkstromanschluss",
				"separator": " "
			},
			{
				"text": "Klingelanlage",
				"separator": " "
			},
			{
				"text": "Gegensprechanlage",
				"separator": " "
			},
			{
				"text": "Gegensprechanlage mit Videoüberwachung",
				"separator": " "
			},
			{
				"text": "Alarmanlage",
				"separator": " "
			},
			{
				"text": "tlw. erneuert",
				"separator": " "
			},
			{
				"text": "tlw. erneuerungsbedürftig",
				"separator": " "
			},
			{
				"text": "tlw. Rohbauzustand",
				"separator": " "
			},
			{
				"text": "mehrere Stromkreise",
				"separator": " "
			},
			{
				"text": "tlw. Einbauleuchten",
				"separator": " "
			},
			{
				"text": "Außenbeleuchtung",
				"separator": " "
			},
			{
				"text": "aufwendige Außenbeleuchtung",
				"separator": " "
			},
			{
				"text": "Leitungen im KG auf Putz",
				"separator": " "
			},
			{
				"text": "Leitungen im KG unter Putz",
				"separator": " "
			}
		],
		"counters": [
			{
				"text": "keine",
				"separator": " "
			},
			{
				"text": "Wasser",
				"separator": " "
			},
			{
				"text": "Strom",
				"separator": " "
			},
			{
				"text": "Allgemeinstrom",
				"separator": " "
			},
			{
				"text": "Heizung",
				"separator": " "
			},
			{
				"text": "und Warmwasser",
				"separator": " "
			}
		],
		"ventilation": [
			{
				"text": "keine besonderen Lüftungsanlagen (herkömmliche Fensterlüftung)",
				"separator": " "
			},
			{
				"text": "nur",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "einfache Schachtlüftung in innenliegenden Räumen",
				"separator": " "
			},
			{
				"text": "aus Faserzementrohren",
				"separator": " "
			},
			{
				"text": "Lüftungsschächte",
				"separator": " "
			},
			{
				"text": "gemauert",
				"separator": " "
			},
			{
				"text": "aus flexiblen Metallrohren als",
				"separator": " "
			},
			{
				"text": "als Fertigschornstein",
				"separator": " "
			},
			{
				"text": "errichtet.",
				"separator": " "
			},
			{
				"text": "mechanische, d.h. ventilatorbetriebene Lüftung als Einzelraumlüfter (Schachtlüftung)",
				"separator": " "
			},
			{
				"text": "in innenliegendem Bad / WC (Fensterlüfter) in der Küche",
				"separator": " "
			},
			{
				"text": "in der Küche (Wandlüfter)",
				"separator": " "
			},
			{
				"text": "mit gleichzeitiger Be- und Entlüftung",
				"separator": " "
			},
			{
				"text": "als zentrale Abluftanlage",
				"separator": " "
			},
			{
				"text": "als zentrale kombinierte Be- und Entlüftungsanlage, Baujahr",
				"separator": " "
			},
			{
				"text": "Lufttransport der Luftkanäle über Rohre",
				"separator": " "
			},
			{
				"text": "im Bereich der Fenster",
				"separator": " "
			},
			{
				"text": "über den Heizkörpern",
				"separator": " "
			},
			{
				"text": "zentrale Lüftungstechnik im Keller",
				"separator": " "
			},
			{
				"text": "zentrale Lüftungstechnik im Dachboden",
				"separator": " "
			},
			{
				"text": "zentrale Lüftungstechnik in der Garage",
				"separator": " "
			},
			{
				"text": "mit Wärmerückgewinnung durch Einzellüftungsgeräte",
				"separator": " "
			},
			{
				"text": "direkt an der Außenwand angebracht, Baujahr",
				"separator": " "
			}
		],
		"heating": [
			{
				"text": "keine",
				"separator": " "
			},
			{
				"text": "(zusätzliche) Einzelöfen",
				"separator": " "
			},
			{
				"text": "elektr. Nachtspeicherheizung",
				"separator": " "
			},
			{
				"text": "Fernheizung",
				"separator": " "
			},
			{
				"text": "Mehrraum-Warmluft-Kachelofen",
				"separator": " "
			},
			{
				"text": "Deckenstrahlungsheizung",
				"separator": " "
			},
			{
				"text": "Zentralheizung",
				"separator": " "
			},
			{
				"text": "Etagenheizung",
				"separator": " "
			},
			{
				"text": "Gaszentralheizung",
				"separator": " "
			},
			{
				"text": "Ölzentralheizung",
				"separator": " "
			},
			{
				"text": "Pellet-Spezialheizkessel",
				"separator": " "
			},
			{
				"text": "Holzschnitzelheizung",
				"separator": " "
			},
			{
				"text": "Brennwertherme",
				"separator": " "
			},
			{
				"text": "Niedertemperatur-Heizkessel",
				"separator": " "
			},
			{
				"text": "Blockheizug mit ${Liter} l",
				"separator": " "
			},
			{
				"text": "Luftwärmepumpe",
				"separator": " "
			},
			{
				"text": "Luft-Wasser-Wärmepumpe",
				"separator": " "
			},
			{
				"text": "Luft-Luft-Wärmepumpe",
				"separator": " "
			},
			{
				"text": "mit Thermostat-Regelung",
				"separator": " "
			},
			{
				"text": "Marke: ${Marke}",
				"separator": " "
			},
			{
				"text": "Typenbezeichnung: ${Bezeichnung}",
				"separator": " "
			},
			{
				"text": "Baujahr: ${Baujahr}",
				"separator": " "
			},
			{
				"text": "Brenner ca.",
				"separator": " "
			},
			{
				"text": "${kW} kW",
				"separator": " "
			},
			{
				"text": "Baujahr des Brenners: ${Baujahr}",
				"separator": " "
			},
			{
				"text": "(Nennwärmeleistung)",
				"separator": " "
			},
			{
				"text": "Abgasverlustwert:",
				"separator": " "
			},
			{
				"text": "Leitungen gedämmt",
				"separator": " "
			},
			{
				"text": "Kamin mit Inliner aus",
				"separator": " "
			},
			{
				"text": "Kunststoff",
				"separator": " "
			},
			{
				"text": "Edelstahl",
				"separator": " "
			},
			{
				"text": "Der Kessel muss erneuert werden.",
				"separator": " "
			},
			{
				"text": "Der Brenner muss erneuert werden.",
				"separator": " "
			},
			{
				"text": "Der Standspeicher muss erneuert werden.",
				"separator": " "
			},
			{
				"text": "Die Steuerung ist defekt.",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "überwiegend",
				"separator": " "
			},
			{
				"text": "Fußbodenheizung",
				"separator": " "
			},
			{
				"text": "Gussheizkörper",
				"separator": " "
			},
			{
				"text": "Stahlradiatoren",
				"separator": " "
			},
			{
				"text": "Flachheizköper",
				"separator": " "
			},
			{
				"text": "Plattenheizkörper",
				"separator": " "
			},
			{
				"text": "Konvektoren",
				"separator": " "
			},
			{
				"text": "tlw. mit Thermostatventilen",
				"separator": " "
			},
			{
				"text": "mit Thermostatventilen",
				"separator": " "
			},
			{
				"text": "Stahltank",
				"separator": " "
			},
			{
				"text": "Kunststofftank",
				"separator": " "
			},
			{
				"text": "im Erdreich",
				"separator": " "
			},
			{
				"text": "im Gebäude",
				"separator": " "
			},
			{
				"text": "ohne Innenbeschichtung",
				"separator": " "
			},
			{
				"text": "Tankgröße ca. ${Volumen in Liter} l",
				"separator": " "
			},
			{
				"text": "Fotovoltaikanlage mit",
				"separator": " "
			},
			{
				"text": "X kWp Leistung",
				"separator": " "
			},
			{
				"text": "montiert und ans Netz gegangen am (im)",
				"separator": " "
			},
			{
				"text": "Einspeisevergütung im Monat: ${Vergütung in €} €",
				"separator": " "
			},
			{
				"text": "Wärmepumpe",
				"separator": " "
			},
			{
				"text": "Solaranlage mit ${Wp} Wp",
				"separator": " "
			},
			{
				"text": "ca. ${Fläche in m²} m²",
				"separator": " "
			},
			{
				"text": "montiert und in Betrieb genommen am ${Datum Montage & Betrieb}",
				"separator": " "
			},
			{
				"text": "Wasserzisterne für Brauchwasser",
				"separator": " "
			}
		],
		"hotWaterInstallations": [
			{
				"text": "ohne",
				"separator": " "
			},
			{
				"text": "zentral über Heizung",
				"separator": " "
			},
			{
				"text": "tlw. zentral über Heizung",
				"separator": " "
			},
			{
				"text": "zentral über Heizung, Standspeicher mit ca. X Liter",
				"separator": " "
			},
			{
				"text": "defekt",
				"separator": " "
			},
			{
				"text": "tlw. Durchlauferhitzer (Elektro)",
				"separator": " "
			},
			{
				"text": "mit Durchlauferhitzer (Elektro)",
				"separator": " "
			},
			{
				"text": "tlw. Boiler (Elektro)",
				"separator": " "
			},
			{
				"text": "Boiler (Elektro)",
				"separator": " "
			},
			{
				"text": "tlw. Durchlauferhitzer (Gas)",
				"separator": " "
			},
			{
				"text": "mit Durchlauferhitzer (Gas)",
				"separator": " "
			},
			{
				"text": "Boiler (Gas)",
				"separator": " "
			},
			{
				"text": "Standspeicher mit ca. X Liter",
				"separator": " "
			},
			{
				"text": "erneuern",
				"separator": " "
			}
		]
	},
	"deprecations": {
		"structuralDamageAndConstructionDefects": [
			{
				"text": "Siehe die besonderen objektspezifischen Grundstücksmerkmale im Gutachten.",
				"separator": " "
			},
			{
				"text": "keine erkennbar",
				"separator": " "
			},
			{
				"text": "keine wesentlichen erkennbar",
				"separator": " "
			},
			{
				"text": "tlw.",
				"separator": " "
			},
			{
				"text": "überwiegend",
				"separator": " "
			},
			{
				"text": "Salzausblühungen im KG",
				"separator": " "
			},
			{
				"text": "Putzschäden",
				"separator": " "
			},
			{
				"text": "Feuchtigkeitsschäden",
				"separator": " "
			},
			{
				"text": "im KG",
				"separator": " "
			},
			{
				"text": "im EG",
				"separator": " "
			},
			{
				"text": "im ${Nummer}. OG",
				"separator": " "
			},
			{
				"text": "im DG",
				"separator": " "
			},
			{
				"text": "im Anbau",
				"separator": " "
			},
			{
				"text": "in der Garage",
				"separator": " "
			},
			{
				"text": "geringfügiger Schädlingsbefall",
				"separator": " "
			},
			{
				"text": "im Dachstuhl",
				"separator": " "
			},
			{
				"text": "im ${Stockwerk}",
				"separator": " "
			},
			{
				"text": "Schimmelbildung",
				"separator": " "
			},
			{
				"text": "Regenfallrohre ohne Anschluss an Kanal",
				"separator": " "
			},
			{
				"text": "Risse in(m)",
				"separator": " "
			},
			{
				"text": "Decke",
				"separator": " "
			},
			{
				"text": "Wand",
				"separator": " "
			},
			{
				"text": "Boden",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Fassade",
				"separator": " "
			},
			{
				"text": "Balkonunterseite",
				"separator": " "
			},
			{
				"text": "an Kamineinfassung",
				"separator": " "
			},
			{
				"text": "Stahlträger angerostet",
				"separator": " "
			},
			{
				"text": "Stahlträger stark verrostet",
				"separator": " "
			},
			{
				"text": "Stahlträger stark verrostet, Standsicherheit gefährdet",
				"separator": " "
			},
			{
				"text": "geringe Kopfhöhe",
				"separator": " "
			},
			{
				"text": "geringe Kopfhöhe der Kellertreppe",
				"separator": " "
			},
			{
				"text": "geringe Kopfhöhe der Erdgeschosstreppe",
				"separator": " "
			},
			{
				"text": "geringe Kopfhöhe der Geschosstreppen",
				"separator": " "
			},
			{
				"text": "Ausführungsmängel",
				"separator": " "
			},
			{
				"text": "optische Mängel",
				"separator": " "
			},
			{
				"text": "Restarbeiten in geringem Umfang erforderlich",
				"separator": " "
			},
			{
				"text": "Restarbeiten im größeren Umfang erforderlich",
				"separator": " "
			}
		],
		"groundplan": [
			{
				"text": "zweckmäßig",
				"separator": " "
			},
			{
				"text": "individuell",
				"separator": " "
			},
			{
				"text": "für das Baujahr zeittypisch",
				"separator": " "
			},
			{
				"text": "bedingt erweiterungsfähig",
				"separator": " "
			},
			{
				"text": "genügt nur bedingt den heutigen Anforderungen",
				"separator": " "
			},
			{
				"text": "genügt nicht den heutigen Anforderungen",
				"separator": " "
			}
		],
		"economicalDeprecation": [
			{
				"text": "Siehe auch die besonderen objektspezifischen Grundstücksmerkmale im Gutachten.",
				"separator": " "
			},
			{
				"text": "keine",
				"separator": " "
			},
			{
				"text": "unwirtschaftliche Grundrisse",
				"separator": " "
			},
			{
				"text": "unwirtschaftliche Raumaufteilung",
				"separator": " "
			},
			{
				"text": "gefangene Räume (sind nur durch andere Zimmer zu erreichen)",
				"separator": " "
			},
			{
				"text": "mangelnde Wärmedämmung",
				"separator": " "
			},
			{
				"text": "übergroße Räume",
				"separator": " "
			},
			{
				"text": "überhöhte Räume",
				"separator": " "
			},
			{
				"text": "mangelnde Raumhöhe",
				"separator": " "
			},
			{
				"text": "mangelnder Schallschutz",
				"separator": " "
			},
			{
				"text": "im KG",
				"separator": " "
			},
			{
				"text": "im EG",
				"separator": " "
			},
			{
				"text": "im OG",
				"separator": " "
			},
			{
				"text": "im DG",
				"separator": " "
			},
			{
				"text": "der obersten Decke",
				"separator": " "
			},
			{
				"text": "der Kellerdecke",
				"separator": " "
			},
			{
				"text": "der Fassade",
				"separator": " "
			}
		],
		"specialBuildingComponents": [
			{
				"text": "keine",
				"separator": " "
			},
			{ "text": "Außentreppe" },
			{
				"text": "Kelleraußentreppe",
				"separator": " "
			},
			{
				"text": "Eingangstreppe",
				"separator": " "
			},
			{
				"text": "Eingangsüberdachung",
				"separator": " "
			},
			{
				"text": "Eingangstreppe mit Eingangsüberdachung",
				"separator": " "
			},
			{
				"text": "Balkon",
				"separator": " "
			},
			{
				"text": "überdachter Balkon",
				"separator": " "
			},
			{
				"text": "Terrasse",
				"separator": " "
			},
			{
				"text": "überdachte Terrasse",
				"separator": " "
			},
			{
				"text": "Loggia",
				"separator": " "
			},
			{
				"text": "Wintergarten",
				"separator": " "
			},
			{
				"text": "Markise",
				"separator": " "
			},
			{
				"text": "Dachaufbauten",
				"separator": " "
			},
			{
				"text": "Gaube(n) an der Vorderseite",
				"separator": " "
			},
			{
				"text": "Gaube(n) auf der Rückseite",
				"separator": " "
			}
		],
		"tanningAndExposureToLight": [
			{
				"text": "gut",
				"separator": " "
			},
			{
				"text": "befriedigend",
				"separator": " "
			},
			{
				"text": "ausreichend",
				"separator": " "
			},
			{
				"text": "schlecht",
				"separator": " "
			},
			{
				"text": "zu gering",
				"separator": " "
			},
			{
				"text": "tlw. unzureichend",
				"separator": " "
			},
			{
				"text": "innenliegende Räume",
				"separator": " "
			}
		],
		"generalAssessment": [
			{
				"text": "tlw."
			},
			{
				"text": "Der bauliche Zustand ist",
				"separator": " "
			},
			{
				"text": "gut.",
				"separator": " "
			},
			{
				"text": "baujahresgemäß",
				"separator": " "
			},
			{
				"text": "gut bis befriedigend.",
				"separator": " "
			},
			{
				"text": "befriedigend.",
				"separator": " "
			},
			{
				"text": "ausreichend.",
				"separator": " "
			},
			{
				"text": "schlecht.",
				"separator": " "
			},
			{
				"text": "desolat.",
				"separator": " "
			},
			{
				"text": "Siehe die besonderen objektspezifischen Grundstücksmerkmale im Gutachten.",
				"separator": " "
			},
			{
				"text": "Es besteht ein",
				"separator": " "
			},
			{
				"text": "geringfügiger",
				"separator": " "
			},
			{ "text": "mittlere" },
			{
				"text": "größerer",
				"separator": " "
			},
			{
				"text": "erheblicher",
				"separator": " "
			},
			{
				"text": "Unterhaltungsstau.",
				"separator": " "
			},
			{
				"text": "Renovierungsbedarf.",
				"separator": " "
			},
			{
				"text": "Unterhaltungsstau und Renovierungsbedarf.",
				"separator": " "
			},
			{
				"text": "Modernisierungsbedarf wegen zeituntypischer Grundrisse.",
				"separator": " "
			},
			{
				"text": "Modernisierungsbedarf wegen überalterter Bodenbeläge.",
				"separator": " "
			},
			{
				"text": "Überalterung der",
				"separator": " "
			},
			{
				"text": "Heizungsanlage",
				"separator": " "
			},
			{
				"text": "Elektroinstallation",
				"separator": " "
			},
			{
				"text": "Sanitärinstallation",
				"separator": " "
			},
			{
				"text": "Bad erneuern",
				"separator": " "
			},
			{
				"text": "Bäder erneuern",
				"separator": " "
			},
			{
				"text": "Bäder / WC erneuern",
				"separator": " "
			},
			{
				"text": "Fenster",
				"separator": " "
			},
			{
				"text": "erneuerungsbedürftig / sanierungsbedürftig",
				"separator": " "
			},
			{
				"text": "Dachkonstruktion",
				"separator": " "
			},
			{
				"text": "Fassade",
				"separator": " "
			},
			{
				"text": "Modernisierung",
				"separator": " "
			},
			{ "text": "der" },
			{
				"text": "Standsicherheit ist gefährdet, es wird empfohlen einen Statiker diesbezüglich zu beauftragen; das Ergebnis ist zusätzlich zu berücksichtigen",
				"separator": " "
			}
		]
	},
	"outdoorFacility": [
			{
				"text": "Versorgungsanlagen",
				"separator": " "
			},
			{
				"text": "Entwässerungsanlagen",
				"separator": " "
			},
			{
				"text": "vom Hausanschluss bis an das öffentliche Netz",
				"separator": " "
			},
			{
				"text": "Versorgungs- und Entwässerungsanlage vom Hausanschluss bis an das öffentliche Netz bzw. Übergabeschacht",
				"separator": " "
			},
			{
				"text": "Wegbefestigung",
				"separator": " "
			},
			{
				"text": "Hofbefestigung",
				"separator": " "
			},
			{
				"text": "befestigte Stellplatzfläche",
				"separator": " "
			},
			{
				"text": "Stellplatz",
				"separator": " "
			},
			{
				"text": "${Anzahl} Stellplätze",
				"separator": " "
			},
			{
				"text": "befestigt mit",
				"separator": " "
			},
			{
				"text": "Schotter",
				"separator": " "
			},
			{
				"text": "roter Erde",
				"separator": " "
			},
			{
				"text": "Rasensteinen",
				"separator": " "
			},
			{
				"text": "Verbundsteinen",
				"separator": " "
			},
			{
				"text": "Betonplatten",
				"separator": " "
			},
			{
				"text": "Carport",
				"separator": " "
			},
			{
				"text": "Carport für zwei Pkws",
				"separator": " "
			},
			{
				"text": "Gartenanlagen und Pflanzungen",
				"separator": " "
			},
			{
				"text": "parkähnliche Gartenanlage",
				"separator": " "
			},
			{
				"text": "Terrasse",
				"separator": " "
			},
			{
				"text": "Stützmauer",
				"separator": " "
			},
			{ "text": "Außentreppe" },
			{
				"text": "Einfriedung (Mauer)",
				"separator": " "
			},
			{
				"text": "Einfriedung (Zaun)",
				"separator": " "
			},
			{
				"text": "Einfriedung (Hecken)",
				"separator": " "
			},
			{
				"text": "Garten terrassenartig angelegt",
				"separator": " "
			},
			{
				"text": "Nutzgarten",
				"separator": " "
			},
			{
				"text": "Steingarten",
				"separator": " "
			},
			{
				"text": "kleines Gewächshaus",
				"separator": " "
			},
			{
				"text": "Gewächshaus",
				"separator": " "
			},
			{
				"text": "Schwimmbad",
				"separator": " "
			},
			{
				"text": "Pool",
				"separator": " "
			},
			{
				"text": "Schwimmteich",
				"separator": " "
			},
			{
				"text": "Gartenteich",
				"separator": " "
			},
			{
				"text": "Biotop",
				"separator": " "
			},
			{
				"text": "Sickergrube",
				"separator": " "
			},
			{
				"text": "Brunnen",
				"separator": " "
			},
			{
				"text": "Grillplatz",
				"separator": " "
			},
			{
				"text": "Gartenhaus",
				"separator": " "
			},
			{
				"text": "Holzschuppen",
				"separator": " "
			},
			{
				"text": "Massiv gebautes Gartenhaus",
				"separator": " "
			},
			{
				"text": "Standplatz für Mülltonnen",
				"separator": " "
			},
			{
				"text": "Zisterne mit ${Wert in m³} m³",
				"separator": " "
			},
			{
				"text": "Gastank",
				"separator": " "
			},
			{
				"text": "Öltank",
				"separator": " "
			},
			{
				"text": "fest eingebauter Flaggenmast",
				"separator": " "
			},
			{
				"text": "Funkmast",
				"separator": " "
			},
			{
				"text": "Kinderspielplatz",
				"separator": " "
			},
			{
				"text": "Remise",
				"separator": " "
			}
		],
	"additionalBuildings": [
			{
				"text": "Freistehende",
				"separator": " "
			},
			{
				"text": "einseitig angebaute",
				"separator": " "
			},
			{
				"text": "zweiseitig angebaute",
				"separator": " "
			},
			{
				"text": "innenliegende",
				"separator": " "
			},
			{
				"text": "Garage",
				"separator": " "
			},
			{
				"text": "Doppelgarage",
				"separator": " "
			},
			{
				"text": "Tiefgaragenplatz",
				"separator": " "
			},
			{
				"text": "Fertiggarage",
				"separator": " "
			},
			{
				"text": "Blechgarage",
				"separator": " "
			},
			{
				"text": "massiv",
				"separator": " "
			},
			{
				"text": "eingeschossig",
				"separator": " "
			},
			{
				"text": "zweigeschossig",
				"separator": " "
			},
			{
				"text": "nicht unterkellert",
				"separator": " "
			},
			{
				"text": "unterkellert",
				"separator": " "
			},
			{
				"text": "mit Montagegrube",
				"separator": " "
			},
			{
				"text": "Strom- und Wasseranschluss",
				"separator": " "
			},
			{
				"text": "Stromanschluss",
				"separator": " "
			},
			{
				"text": "Wasseranschluss",
				"separator": " "
			},
			{
				"text": "Satteldach",
				"separator": " "
			},
			{
				"text": "Walmdach",
				"separator": " "
			},
			{
				"text": "Krüppelwalmdach",
				"separator": " "
			},
			{
				"text": "Flachdach",
				"separator": " "
			},
			{
				"text": "Dachziegel(Ton)",
				"separator": " "
			},
			{
				"text": "Betondachstein",
				"separator": " "
			},
			{
				"text": "Biberschwanzeindeckung",
				"separator": " "
			},
			{
				"text": "Falzblech",
				"separator": " "
			},
			{
				"text": "Zinkdoppelstehfalz",
				"separator": " "
			},
			{
				"text": "Trapezblech",
				"separator": " "
			},
			{
				"text": "Bitumendachbahnen",
				"separator": " "
			},
			{
				"text": "Wellplatten",
				"separator": " "
			},
			{
				"text": "Dachpappschindeln",
				"separator": " "
			},
			{
				"text": "Dachpappe",
				"separator": " "
			},
			{
				"text": "Faserzementplatten",
				"separator": " "
			},
			{
				"text": "Naturschiefer",
				"separator": " "
			},
			{
				"text": "Kunstschiefer",
				"separator": " "
			},
			{
				"text": "Reet",
				"separator": " "
			},
			{
				"text": "Schindeln (Bitumen)",
				"separator": " "
			},
			{
				"text": "Betonboden",
				"separator": " "
			},
			{
				"text": "Estrich",
				"separator": " "
			},
			{
				"text": "mit Anstrich",
				"separator": " "
			},
			{
				"text": "Fliesen",
				"separator": " "
			},
			{
				"text": "Schwingtor aus",
				"separator": " "
			},
			{
				"text": "Sektionaltor aus",
				"separator": " "
			},
			{
				"text": "Stahl",
				"separator": " "
			},
			{
				"text": "Aluminium",
				"separator": " "
			},
			{
				"text": "ohne elektrischen Antrieb",
				"separator": " "
			},
			{
				"text": "mit elektrischem Antrieb",
				"separator": " "
			},
			{
				"text": "Rolltor aus",
				"separator": " "
			},
			{
				"text": "Flügeltüren aus",
				"separator": " "
			},
			{
				"text": "Holz",
				"separator": " "
			},
			{
				"text": "Stahl",
				"separator": " "
			},
			{
				"text": "Aluminium",
				"separator": " "
			},
			{
				"text": "asphaltierte(r)",
				"separator": " "
			},
			{
				"text": "Scheune",
				"separator": " "
			},
			{
				"text": "Schuppen",
				"separator": " "
			},
			{
				"text": "Werkstätte",
				"separator": " "
			},
			{
				"text": "Umspannstation",
				"separator": " "
			},
			{
				"text": "Einrichtungen und Anlagen für die Tierhaltung (Ställe, Wasserbecken etc.)",
				"separator": " "
			},
			{
				"text": "renovierungsbedürftig",
				"separator": " "
			},
			{
				"text": "stark renovierungsbedürftig",
				"separator": " "
			},
			{
				"text": "stark renovierungsbedürftig und so nicht zu nutzen",
				"separator": " "
			},
			{
				"text": "Abriss",
				"separator": " "
			},
			{
				"text": "Mülltonnenhäuschen",
				"separator": " "
			},
			{
				"text": "Klärgrube",
				"separator": " "
			},
			{
				"text": "Ausfahrgrube",
				"separator": " "
			},
			{
				"text": "Remise",
				"separator": " "
			}
	],
	"volumeCalculation": {
		"calculationBase": [
			{
				"text": "Berechnung des Rauminhaltes als",
				"separator": " "
			},
			{
				"text": "Brutto-Rauminhalt (BRI)",
				"separator": " "
			},
			{
				"text": "Brutto-Geschoss-Fläche (BGF)",
				"separator": " "
			},
			{
				"text": "Die Berechnung erfolgte aus",
				"separator": " "
			},
			{
				"text": "Fertigmaßen",
				"separator": " "
			},
			{
				"text": "Rohbaumaßen",
				"separator": " "
			},
			{
				"text": "Fertig- und Rohbaumaßen",
				"separator": " "
			},
			{
				"text": "auf der Grundlage von",
				"separator": " "
			},
			{
				"text": "örtlichem Aufmaß",
				"separator": " "
			},
			{
				"text": "Bauzeichnungen",
				"separator": " "
			},
			{
				"text": "örtlichem Aufmaß und Bauzeichnungen",
				"separator": " "
			},
			{
				"text": "Die vorhandene Berechnung wurde",
				"separator": " "
			},
			{
				"text": "überprüft und ggf. ergänzt",
				"separator": " "
			},
			{
				"text": "Die Berechnung erfolgte",
				"separator": " "
			},
			{
				"text": "aufgrund der Außenbesichtigung.",
				"separator": " "
			},
			{
				"text": "Zur Berechnung wurde die Flurkarte verwendet und die Höhen wurden geschätzt bzw. Erfahrungswerte angenommen.",
				"separator": " "
			}
		],
		"types": [
			{ "text": "Gebäude" },
			{ "text": "Erker" },
			{ "text": "Teilbereich" },
			{ "text": "Balkon" },
			{ "text": "Anbau" },
			{ "text": "Eingangstreppe" },
			{ "text": "Nebengebäude" },
			{ "text": "Gartenhaus" },
			{ "text": "Garage" },
			{ "text": "Doppelgarage" }
		]
	},
	"spaceCalculation": {
		"calculationBase": [
			{
				"text": "Die Berechnung erfolgt aus",
				"separator": " "
			},
			{
				"text": "Fertigmaßen",
				"separator": " "
			},
			{
				"text": "Rohbaumaßen",
				"separator": " "
			},
			{
				"text": "Fertig- und Rohbaumaßen",
				"separator": " "
			},
			{
				"text": "auf der Grundlage von",
				"separator": " "
			},
			{
				"text": "örtlichem Aufmaß",
				"separator": " "
			},
			{
				"text": "Bauzeichnungen",
				"separator": " "
			},
			{
				"text": "örtlichem Aufmaß und Bauzeichnungen",
				"separator": " "
			},
			{
				"text": "wohnwertabhängig",
				"separator": " "
			},
			{
				"text": "nach DIN 283",
				"separator": " "
			},
			{
				"text": "nach DIN 277",
				"separator": " "
			},
			{
				"text": "nach WoFIV",
				"separator": " "
			},
			{
				"text": "gemäß den zur Verfügung gestellten Unterlagen",
				"separator": " "
			}
		]
	},
	"livingSpace": [
			{
				"text": "Die Wohnfläche beträgt laut Angaben des Auftraggebers / Eigentümers X m².",
				"separator": " "
			},
			{
				"text": "Laut Mietvertrag beträgt die Wohnfläche X m².",
				"separator": " "
			},
			{
				"text": "Die Wohnfläche beträgt laut durchgeführtem Aufmaß X m².",
				"separator": " "
			},
			{
				"text": "Die Wohnfläche beträgt laut der zur Verfügung gestellten Unterlagen X m².",
				"separator": " "
			},
			{
				"text": "Die Wohnflächenberechnung wurde auftragsgemäß nicht überprüft.",
				"separator": " "
			},
			{
				"text": "Die Wohnfläche wurde von dem Sachverständigen aufgemessen.",
				"separator": " "
			},
			{
				"text": "Die Wohnfläche wurde von der Sachverständigen aufgemessen.",
				"separator": " "
			},
			{
				"text": "Die Wohnflächenberechnung wurde ohne erneutes Aufmaß rechnerisch überprüft und gegebenenfalls ergänzt.",
				"separator": " "
			},
			{
				"text": "Die Wohnflächenangabe ist zwischen den Parteien unstrittig.",
				"separator": " "
			},
			{
				"text": "Die Wohnflächenangabe ist zwischen den Parteien strittig.",
				"separator": " "
			}
		]
};