export function Space() {
	this.width = '';
	this.length = '';
	this.isNegative = false;
	this.factor = 1;
	this.plasterDeduction = 0;
	this.livingValueApproach = 1;
	this.isAreaPart = true;

	this.area = function () {
		var area = this.width * this.length * this.factor * (1 - this.plasterDeduction) * this.livingValueApproach;
		if (this.isNegative)
			area = area * -1;
		return area;
	};
}

Space.defaultPlasterDeduction = 0.02;