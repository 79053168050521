﻿import {DescriptionUnit} from '../models/DescriptionUnit'
import {DescriptionUnitDto} from '../models/DescriptionUnitDto'
import {Floor} from '../models/Floor'
import {FloorDto} from '../models/FloorDto'
import {Project} from '../models/Project';
import {ProjectDto} from  '../models/ProjectDto'
import {Room} from '../models/Room'
import {RoomDto} from '../models/RoomDto'
import {Space} from '../models/Space'
import {SpaceDto} from '../models/SpaceDto'
import {SpaceUnit} from '../models/SpaceUnit'
import {SpaceUnitDto} from '../models/SpaceUnitDto'
import {Volume} from '../models/Volume'
import {VolumeDto} from '../models/VolumeDto'
import {VolumeUnit} from '../models/VolumeUnit'
import {VolumeUnitDto} from '../models/VolumeUnitDto'

angular.module("repositories").factory("projectRepository", function ($http, mapperService, $localForage, $q) {

	var saveInterval = 20;
	var lastSaveAt = 0;

	function checkResponseSuccessful(response) {
		return response.status >= 200 && response.status <= 299;
	}

	var mapSingleToObject = function (data) {
		return mapperService.map(data, Project);
	};

	var mapManyToObject = function (data) {
		var projects = [];

		angular.forEach(data, function (item)
		{
			projects.push(mapSingleToObject(item));
		});

		return projects;
	};

	var mapToDto = function(data) {
		return mapperService.map(data, ProjectDto);
	};

	async function getAllProjectsFromCache() {
		let projects = await  $localForage.getItem('cachedProjects');
		projects = JSON.parse(projects);
		if (! projects)
			return [];
		
		return mapManyToObject(projects);
	}

	function getCachedProjects() {
		return $localForage.getItem('cachedProjects')
			.then(function (cachedProjects) {
				cachedProjects = angular.fromJson(cachedProjects);
				if (cachedProjects)
					cachedProjects = mapManyToObject(cachedProjects);
				else
					cachedProjects = [];

				return cachedProjects;
			}, function () {
				return [];
			});
	}

	function cacheProjects(projects) {
		return $localForage.setItem('cachedProjects', angular.toJson(projects));
	}

	async function cacheProjectsAsync(projects) {
		await $localForage.setItem('cachedProjects', angular.toJson(projects));
	}

	async function getCachedProjectsAsync() {
		let projects = await $localForage.getItem('cachedProjects');
		if (! projects)
			return [];

		projects = JSON.parse(projects);
		if (! projects)
			return [];
		
		return projects;
	}

	function getCachedProject(projectId) {
		return getCachedProjects()
			.then(function (cachedProjects) {
				for (var index in cachedProjects) {
					if (cachedProjects[index].id == projectId)
						return cachedProjects[index];
				}
				return null;
			}, function () {
				return null;
			});
	}

	function deleteCachedProject(projectId) {
		return getCachedProjects()
			.then(function (cachedProjects) {
				angular.forEach(cachedProjects, function (cachedProject, index) {
					if (cachedProject.id == projectId)
						cachedProjects.splice(index, 1);
				});

				return cacheProjects(cachedProjects);
			}, function () {
				return null;
			});
	}
	
	async function saveProjectOnServerAsync(project) {
		await $http.post(CONFIG.backendUrl + '/api/projects', mapToDto(project));
	}

	async function getProjectsFromServerAsync() {
		const response = await $http.get(CONFIG.backendUrl + "/api/projects");
			
		if (response.status != 200) {
			return [];
		}

		return mapManyToObject(response.data);
	}
	
	async function deleteProjectOnServerAsync(project) {
		await $http.delete(CONFIG.backendUrl + "/api/projects/" + project.id);
	}

	var root = {
		getAllAsync: async function(includeDeleted = false) {
			const root = this;
			let projects = await getCachedProjects();
			if (!projects) {
				projects = await getProjectsFromServerAsync();
				if (projects)
					await cacheProjects(projects);
			}

			if (! includeDeleted)
				projects = projects.filter(e => !e.$isDeleted);

			return projects;	
		},

		getAll: async function() {
			const root = this;
			let projects = await getCachedProjects();
			if (! projects) {
				projects = await getProjectsFromServerAsync();
				if (projects)
					await cacheProjects(projects);
			}

			projects = projects.filter(e => !e.$isDeleted);
			
			return projects;				
		},

		mergeProjects: function(targetList, sourceList) {
			let finalList = [];
			for (let targetProject of targetList) {
				let found = false;
				for (let project of finalList) {
					if (project.id && targetProject.id && project.id == targetProject.id) {
						found = true;
						break;
					}
				}

				if (found) {
					continue;
				}

				found = false;
				for (let srcProject of sourceList) {
					if (targetProject.id && srcProject.id && targetProject.id == srcProject.id) {
						if (srcProject.updatedAt && targetProject.updatedAt && srcProject.updatedAt >= targetProject.updatedAt) {
							finalList.push(srcProject);
							found = true;
							break;
						}
					}
				}
				
				if (! found) {
					finalList.push(targetProject);
				}
			}

			for (let srcProject of sourceList) {

				let found = false;
				for (let project of finalList) {
					if (srcProject.id && project.id && project.id == srcProject.id) {
						found = true;
						break;
					}
				}

				if (found) {
					continue;
				}

				finalList.push(srcProject);
			}

			return finalList;
		},

		getByIdAsync: async (id) => {
			const projects = await getCachedProjectsAsync();
			if (! projects || !Array.isArray(projects))
				return null;
			
			return projects.find(e => e.id == id);

		},

		getById: function(id) {
			return getCachedProject(id)
				.then(function (cachedProject) {
					if (cachedProject)
						return cachedProject;

					return $http.get(CONFIG.backendUrl + "/api/projects/" + id)
						.then(function (response) {
							return mapSingleToObject(response.data);
						});
				});
		},
		
		saveAsync: async function(project) {
			project.$hasUnsyncedChanges = true;
			let projects = await getCachedProjectsAsync();
			if (! projects)
				projects = [];

			let index = projects.findIndex(e => e.id == project.id);
			if (index > -1)
				projects.splice(index, 1);
			
			projects.push(project);
			await cacheProjectsAsync(projects);
		},
		
		syncAsync: async function() {
			let localProjects = await getCachedProjectsAsync();
			if (localProjects) {
				for (let project of localProjects) {
					if (project.$hasUnsyncedChanges) {
						if (project.$isDeleted) {
							await deleteProjectOnServerAsync(project);
						}
						else {
							await saveProjectOnServerAsync(project);
						}
					}
				}
			}

			let remoteProjects = await getProjectsFromServerAsync();
			if (remoteProjects) {
				await cacheProjectsAsync(remoteProjects);
			}

			return remoteProjects;
		},

		save: function (project, forceImmediately) {
			forceImmediately = forceImmediately || false;
			return $q.when((function () {
				// if (Date.now() / 1000 - lastSaveAt > saveInterval || forceImmediately) {
				if (false) {
					lastSaveAt = Date.now() / 1000;
					console.log("saving online");
					return $http.post(CONFIG.backendUrl + "/api/projects", mapToDto(project))
						.then(function (response) {
							project.$hasUnsyncedChanges = false;
							return mapSingleToObject(response.data);
						}, function () {
							project.$hasUnsyncedChanges = true;
						});
				}
				else {
					if (! project.$hasUnsyncedChanges)
						project.$hasUnsyncedChanges = true;
				}
				return project;
			})())			
			.finally(function () {
				return getCachedProjects()
					.then(function (cachedProjects) {
						angular.forEach(cachedProjects, function (cachedProject, index) {
							if (cachedProject.id == project.id)
								cachedProjects.splice(index, 1);
						});

						cachedProjects.push(project);
						return cacheProjects(cachedProjects);
					});
			});
		},

		deleteAsync: async function(project) {
			project.$isDeleted = true;
			project.$hasUnsyncedChanges = true;
			await root.saveAsync(project);
			// await deleteProjectOnServerAsync(project);
		},
		
		delete: function(project) {
		    return $http.delete(CONFIG.backendUrl + "/api/projects/" + project.id)
				.then(function(response) {
					return true;
				})
				.then(function() {
					return deleteCachedProject(project.id);
				});
		},

		logoutAsync: async function() {
			await $localForage.clear();
		}
	};

	return root;
});