﻿(function () {
	'use strict';

	angular.module('directives')

	.directive('skAutofocus', ['$timeout',
	  function ($timeout) {
	  	return {
	  		restrict: 'A',
	  		scope: {
				isActive: '@skAutofocus'
	  		},
	  		link: function ($scope, $element) {
	  			if ($scope.isActive) {
	  				$timeout(function () {
	  					$element[0].focus();
	  				});
	  			}
	  		}
	  	};
	  }
	]);
})();