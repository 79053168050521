﻿import {Space} from './Space'

export function SpaceUnit()
{
	this.id = '';
	this.name = '';
	this.descriptionUnitId = '';
	this.spaces = [];

	this.deleteSpace = function (space) {
		var index = this.spaces.indexOf(space);
		if (index > -1)
			this.spaces.splice(index, 1);
	};

	this.area = function () {
		var res = 0;
		for (var index in this.spaces) {
			res += this.spaces[index].area();
		}
		return res;
	};
}

SpaceUnit.getTyping = function() { return {
        spaces: {
            _isArray: true,
            _type: Space
        }
}};