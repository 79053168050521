require('./mapper');
angular.module("services").factory("mapperService", function() {
	return new function Mapper() {
		var self = this;
	
		var mapExtractSourceData = function (sourceObject, propertyName, defaultValue) {
			if (sourceObject == null)
				return null;
			var lowerCamelCase = propertyName.charAt(0).toLowerCase() + propertyName.substr(1);
			var upperCamelCase = propertyName.charAt(0).toUpperCase() + propertyName.substr(1);
			var defaultValue = defaultValue || null;
	
			return sourceObject[propertyName] || sourceObject[lowerCamelCase] || sourceObject[upperCamelCase] || defaultValue;
		};
	
		this.mapMany = function (source, target, typingInformation) {
			var res = [];
			if (!Array.isArray(source) || !(target instanceof Function))
				return res;
			source.forEach(function (element) {
				res.push(this.map(element, target, typingInformation))
			}, this);
			return res;
		};
	
		this.map = function (source, target, typingInformation) {
			var typingInformation = typingInformation || {};
	
			// special handling for target type Date
			if (typeof(target) == 'function' && target == Date) {
				return new Date(source);
			}
			
			// create instance of target type
			if (target instanceof Function) {
				typingInformation = (target.getTyping) ? target.getTyping() : typingInformation;
				target = new target();
			}
			else if (typeof (target) == 'object') {
				typingInformation = (target.constructor.getTyping) ? target.constructor.getTyping() : typingInformation;
			}
	
	
			for (var property in target) {
				var sourceData = mapExtractSourceData(source, property);
	
				if (sourceData == null)
					continue;
	
				// filter out functions / methods
				if (typeof (target[property]) == 'function')
					continue;
				
				// property is array
				if (typingInformation[property] && typingInformation[property]._isArray) {
					var targetType = typingInformation[property]._type || Object;
	
					target[property] = [];
	
					// source data is array
					if (Array.isArray(sourceData) && sourceData.length > 0) {
	
						sourceData.forEach(function (element) {
							target[property].push(self.map(element, targetType, null));
						}, this);
					}
					// source data is array but empty
					else if (Array.isArray(sourceData) && sourceData.length == 0) {
						continue;
					}
					// source data is object instead of array (no array because of single element) -> convert to array of objects
					else {
						var ele = self.map(sourceData, new targetType());
						target[property].push(ele);
					}
				}
	
				// property typing information exists	
				else if (typingInformation[property] && typeof(typingInformation[property]._type) == 'function') {
					target[property] = self.map(sourceData, typingInformation[property]._type, null);
				}
				// property is object without typing information
				else if (typeof (target[property]) == 'object' || typeof (sourceData) == 'object') {				
					target[property] = self.map(sourceData, target[property] ||  Object, typingInformation[property] || null);
				}
				// property is simple type
				else {
					target[property] = sourceData;
				}
			}
	
			return target;
		};
	}
});