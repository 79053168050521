import {Floor} from '../models/Floor'

angular.module("controllers").controller("projectBuildingConstructionController", function ($scope, $controller, project, itemDataService, ModalService, $state, projectService) {
	$controller("projectBaseController", { $scope: $scope, project: project, itemDataService: itemDataService });

	$scope.addFloorsFromTemplate = function(template) {
		var floorTypes = [];
		if (template == 'template1') {
			floorTypes.push(Floor.Types.Basement.id,
				Floor.Types.Groundfloor.id,
				Floor.Types.Attic.id);
		}	
		else if (template == 'template2') {
			floorTypes.push(Floor.Types.Basement.id,
				Floor.Types.Groundfloor.id,
				Floor.Types.Upstairs.id,
				Floor.Types.Attic.id);
		}
		else if (template == 'template3') {
			floorTypes.push(Floor.Types.Basement.id,
				Floor.Types.Groundfloor.id,
				Floor.Types.Upstairs.id,
				Floor.Types.Upstairs.id,
				Floor.Types.Attic.id);
		}

		for (let floorType of floorTypes) {
			let floor = projectService.createNewFloor(floorType, $scope.project);
			if (floor != false) {
				$scope.project.floors.push(floor);
			}
		}
	};

	$scope.addFloor = function () {


		ModalService.showModal({
			template: require('./chooseFloorTypeDialog.html'),
			controller: 'chooseFloorTypeDialogController',
			closeDelay:500, 
			preClose: (modal) => { modal.element.modal('hide')}
		})
		.then(function (modal) {
			modal.element.modal();
			return modal.close;
		})
		.then(function (res) {
			console.log("floor type", res);
			if (res != false && res.length > 0) {
				var floor = projectService.createNewFloor(res, $scope.project);

				if (floor != false) {
					$scope.project.floors.push(floor);
				}
			}
			return;
		});
	};
});