﻿angular.module("controllers").controller("descriptionUnitController", function ($scope, $q, $controller, project, itemDataService, ngDialog, $state, projectService, descriptionUnit) {
	$controller("descriptionUnitBaseController", { $scope: $scope, project: project, itemDataService: itemDataService, descriptionUnit: descriptionUnit });

	$scope.deleteDescriptionUnit = function () {
		$scope.project.deleteDescriptionUnit($scope.descriptionUnit);
		$state.go('project-description-and-partition', { projectId: $scope.project.id });
	};

	$scope.duplicateDescriptionUnitAsync = async function() {
		let copy = projectService.duplicateDescriptionUnit($scope.descriptionUnit);
		$scope.project.descriptionAndPartition.units.push(copy);
		await projectService.saveAsync($scope.project);
	};

});