﻿angular.module("controllers").controller("loginDialogController", function ($scope, $timeout, userService, $q, close) {
	
	$scope.closeThisDialog = close;

	$scope.username = "";
	$scope.password = "";

	$scope.resultSuccess = false;
	$scope.resultError = false;

	$scope.processing = false;

	$scope.done = function () {
		$scope.processing = true;
		$scope.resultError = false;
		$scope.resultSuccess = false;

	    userService.login($scope.username, $scope.password)
            .then(function (result) {
            	console.log("login result", result);

            	$scope.processing = false;

            	if (result) {
            		$scope.resultSuccess = true;
            		return $timeout(3000);
            	}
            	else {
            		$scope.resultError = true;
            		return $q.reject();
            	}
            })
            .then(function() {
            	$scope.closeThisDialog(true, 200);
            });
		
	};

	$scope.cancel = function () {
		$scope.closeThisDialog(false, 200);
	};

	$scope.closeSuccess = function () {
		$scope.closeThisDialog(true, 200);
	};

	
});