﻿import {Space} from './Space'

export function Room() {
	this.id = '';
	this.floorId = '';
	this.name = '';
	this.descriptionUnitId = '';
	this.orientation = '';
	this.spaces = [];
	this.sanitation = '';
	this.kitchenEquipment = '';
	this.floorings = '';
	this.panelling = '';
	this.ceiling = '';
	this.windows = '';
	this.deprecation = {
		structuralDamageAndConstructionDefects: '',
		economicalDeprecation: '',
		tanningAndExposureToLight: '',
	};

	this.area = function () {
		var res = 0;
		for (var i in this.spaces) {
			res += this.spaces[i].area();
		}
		return res;
	};

	this.deleteSpace = function (space) {
		var index = this.spaces.indexOf(space);
		if (index > -1)
			this.spaces.splice(index, 1);
		
	};
}

Room.getTyping = function () {
    return {
        spaces: {
            _isArray: true,
            _type: Space
        }
    }
};

Room.Orientations = [
	{
		id: 'street',
		name: 'straßenseitig'
	},
	{
		id: 'garden',
		name: 'gartenseitig'
	},
	{
		id: 'inside',
		name: 'innenliegend'
	},
	{
		id: 'gable',
		name: 'giebelseitig'
	}
]