angular.module("controllers").controller("projectDescriptionAndPartitionController", function ($scope, $controller, project, itemDataService, ModalService, $state, projectService) {
	$controller("projectBaseController", { $scope: $scope, project: project, itemDataService: itemDataService });
	
	$scope.addDescriptionUnit = function() {

		ModalService.showModal({
			template: require('./inputDescriptionUnitNameDialog.html'),
			controller: 'inputDescriptionUnitNameDialogController',
			inputs: {
				itemDataService: itemDataService
			},
			closeDelay:500, 
			preClose: (modal) => { modal.element.modal('hide');}
		})
		.then(function(modal) {
			modal.element.modal();
			return modal.close;
		})
		.then(function(res) {

			if (typeof (res) === 'string' && res.length > 0) {
				
				var descriptionUnit = projectService.createNewDescriptionUnit(res);
				if (descriptionUnit != false) {
					descriptionUnit.name = res;
					project.descriptionAndPartition.units.push(descriptionUnit);
					//$state.go("project-description-and-partition-description-unit", { descriptionUnitId: descriptionUnits.id });
				}
			}
		});
	};
});