import {Volume} from '../models/Volume'

angular.module("controllers").controller("projectVolumeController", function ($scope, $controller, project, projectService, itemDataService, ngDialog) {
	$controller("projectBaseController", { $scope: $scope, project: project, itemDataService: itemDataService });

	$scope.newVolume = [];
	if (Array.isArray (project.volumeCalculation.volumeUnits)) {
		for (var index in project.volumeCalculation.volumeUnits) {
			$scope.newVolume[project.volumeCalculation.volumeUnits[index].id] = new Volume();
		}
	}

	$scope.itemsFor = function (path) {
		return itemDataService.getFor(path);
	};

	$scope.addVolumeUnit = function () {
		var unit = projectService.createNewVolumeUnit();
		if (!Array.isArray(project.volumeCalculation.volumeUnits))
			project.volumeCalculation.volumeUnits = [];
		project.volumeCalculation.volumeUnits.push(unit);
		$scope.newVolume[unit.id] = new Volume();
	};


	$scope.addVolume = function (volumeUnitId) {
		$scope.project.getVolumeUnitById(volumeUnitId).volumes.push($scope.newVolume[volumeUnitId]);
		$scope.newVolume[volumeUnitId] = new Volume();
	};

	$scope.deleteVolume = function (volumeUnitId, volume) {
		$scope.project.getVolumeUnitById(volumeUnitId).deleteVolume(volume);
	};

	$scope.calculateFullVolume = function () {
		return $scope.project.fullVolume();
	};

});