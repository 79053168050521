﻿import {VolumeDto} from './VolumeDto'

export function VolumeUnitDto()
{
	this.Id = '';
	this.Name = '';
	this.Volumes = [];
}

VolumeUnitDto.getTyping = function () {
    return {
        Volumes: {
            _isArray: true,
            _type: VolumeDto
        }
    };
};