export function Floor() {
	this.id = 0;
	this.name = '';
	this.typeId = '';
	this.storeyCeiling = '';
	this.typeOfConstruction = '';
	this.damage = '';
	this.staircase = '';
	this.exteriorStaircase = '';
	this.fitout = {
		hasFitout: false,
		floor: '',
		walls: '',
		ceiling: '',
		windows: '',
		doors: ''	
	};
	this.atticStaircase = '';
	
	var self = this;
	
	this.hasFitoutOption = function() {
		return this.checkType(Floor.Types.Basement);
	};
	
	this.demageValueAvailable = function() {
		return this.checkType(Floor.Types.Basement);
	};
	
	this.exterirorStaircaseValueAvailable = function() {
		return true;	
	};
	
	this.atticStaircaseValueAvailable = function() {
		return this.checkType(Floor.Types.Attic);	
	};

	this.damageValueAvailable = function () {
		return this.checkType(Floor.Types.Basement);
	};

	this.typeOfConstructionValueAvailable = function () {
		return this.checkType(Floor.Types.Basement);
	};
	
	
	this.checkType = function (typeRef) {
		if (this.getType() == typeRef)	
			return true;
		return false;
	};
	
	this.getType = function() {
		for (var i in Floor.Types) {
			if (Floor.Types[i].id == this.typeId)
				return Floor.Types[i];
		}
		return null;
	};
}

Floor.Types =  {
	Basement: {
		id: 'basement',
		name: 'Kellergeschoss',
		allowMultiple: false
	},
Downstairs: {
		id: 'downstairs',
		name: 'Untergeschoss',
		allowMultiple: true
},
Groundfloor: {
		id: 'groundfloor',
		name : 'Erdgeschoss',
		allowMultiple: false
},
Upstairs: {
		id: 'upstairs',
		name: 'Obergeschoss',
		allowMultiple: true
},
Attic: {
		id: 'attic',
		name: 'Dachgeschoss',
		allowMultiple: false
}
};

Floor.getTyping = function () {
    return {
        
    }
};