﻿require("babel-polyfill");
require('./data.json')
const jQuery = require("jquery")
window.$ = window.jQuery = jQuery;
require('bootstrap/dist/css/bootstrap.min.css')
require('bootstrap')
require('angular');
require('angular-ui-router')
require("angular-uuid");
require("ng-dialog");
require("angularjs-filters");
require("angular-localforage");
require('angular-modal-service');
require('angular-ui-notification');

require('./models/DescriptionUnit');
require('./models/DescriptionUnitDto');
require('./models/Floor');
require('./models/FloorDto');
require('./models/Project');
require('./models/ProjectDto');
require('./models/Room');
require('./models/RoomDto');
require('./models/Space');
require('./models/SpaceDto');
require('./models/SpaceUnit');
require('./models/SpaceUnitDto');
require('./models/Volume');
require('./models/VolumeDto');
require('./models/VolumeUnit');
require('./models/VolumeUnitDto');

require('./config')
require('./init');
require('./services/appService')
require('./repositories/projectRepository')
require('./services/httpInterceptorService')
require('./services/itemDataService')
require('./services/mapper')
require('./services/mapperService')
require('./services/projectService')
require('./services/userService')

require('./directives/changeFocusOnEnter')
require('./directives/elastic')
require('./directives/ngEnter')
require('./directives/skAutofocus')
require('./directives/textBuilder')

require('./views/startup')
require('./views/chooseFloorDialog')
require('./views/chooseFloorTypeDialog')
require('./views/descriptionUnit')
require('./views/descriptionUnitBase')
require('./views/descriptionUnitDescription')
require('./views/descriptionUnitGeneralTechnicalEquipment')
require('./views/floor')
require('./views/info')
require('./views/inputDescriptionUnitNameDialog')
require('./views/inputRoomNameDialog')
require('./views/projectBase')
require('./views/projectBuildingConstruction')
require('./views/projectDescriptionAndPartition')
require('./views/projectMain')
require('./views/projectSpace')
require('./views/projectVolume')
require('./views/room')
require('./views/sendProjectByMailDialog')
require('./views/syncChangesDialog')
require('./views/textBlockFillPlaceholdersDialog')
require('./views/projectsList')

require('./views/loginDialog')
//ngLocale
angular.module("app", ["ui.router", "angular-uuid", "ngDialog", "controllers", "repositories", "services", "directives", "ch.filters", "LocalForageModule", 'angularModalService', 'ui-notification']);


angular.module("app").config(function () {
});

angular.module("app").run(function ($rootScope, ngDialog, ModalService, userService, $transitions) {
	console.log("test2");

	$rootScope.$on("$stateChangeError", console.log.bind(console));
	$rootScope.$on('$stateChangeSuccess', function () {
		//document.body.scrollTop = document.documentElement.scrollTop = 0;
	});

	$rootScope.$on('$stateChangeStart', function (ev, to, toParams, from, fromParams) {
		if (! $rootScope.sessionRestored) {
			$rootScope.sessionRestored = true;
			userService.restoreSession();
		}
	});

	$transitions.onBefore({}, function(trans) {
		if (! $rootScope.sessionRestored) {
			$rootScope.sessionRestored = true;
			return userService.restoreSession();
		}
	});

	// handle unauthorized
	$rootScope.$on('httpUnauthorized', function () {

		ModalService.showModal({
			template: require('./views/loginDialog.html'),
			controller: 'loginDialogController',
			closeDelay: 500,
			preClose: (modal) => { modal.element.modal('hide'); }
		})
		.then(function (modal) {
		    console.log(modal);
			modal.element.modal();
			return;
		});

	});

});

angular.module('app').config(function (ModalServiceProvider, $httpProvider, ngDialogProvider, $uiViewScrollProvider) {
	$httpProvider.interceptors.push('httpInterceptorService');

	ngDialogProvider.setDefaults({
		className: 'ngdialog-theme-default',
		showClose: false,
		closeByDocument: true,
		closeByEscape: true,
		appendTo: '#dialog-target',
		trapFocus: false,
		preserveFocus: true
	});

	ModalServiceProvider.configureOptions({closeDelay:500, preClose: (modal) => { modal.element.modal('hide'); }});
	//$uiViewScrollProvider.useAnchorScroll();
});

angular.module("app").config(function ($stateProvider, $urlRouterProvider) {
	$urlRouterProvider.otherwise("/");

	$stateProvider.state("startup", {
		url: "/",
		template: require("./views/startup.html"),
		controller: "startupController"
	});

	$stateProvider.state('project', {
		abstract: true,
		url: "/projects/{projectId}",
		template: require('./views/projectBase.html'),
		controller: 'projectBaseController',

		resolve: {
			project: function (projectService, $stateParams) {
				return projectService.getById($stateParams.projectId);
			},

			itemDataService: function (itemDataServiceProvider) {
				return itemDataServiceProvider.getService();
			}
		}
	});

	$stateProvider.state('descriptionUnitBase', {
		abstract: true,
		parent: 'project',
		url: '/descriptionAndPartition/descriptionUnit/{descriptionUnitId}',
		template: '<ui-view autoscroll="false" />',

		resolve: {
			descriptionUnit: function (project, $q, $stateParams) {
				var deferred = $q.defer();
				var targetUnit = null;

				angular.forEach(project.descriptionAndPartition.units, function (descriptionUnit) {
					if (descriptionUnit.id == $stateParams.descriptionUnitId) {
						targetUnit = descriptionUnit;
					}
				});

				if (targetUnit == null)
					deferred.reject();
				else
					deferred.resolve(targetUnit);

				return deferred.promise;
			}
		}
	});

	$stateProvider.state('info', {
		url: '/info',
		template: require('./views/info.html'),
		controller: 'infoController'
	});

	$stateProvider.state('manual', {
		url: '/manual',
		template: require('./views/manual.html')
	});

	$stateProvider.state('agb', {
		url: '/agb',
		template: require('./views/agb.html')
	});

	$stateProvider.state('impressum', {
		url: '/impressum',
		template: require('./views/impressum.html')
	});

	$stateProvider.state("projects-list", {
		url: "/projects",
		template: require("./views/projectsList.html"),
		controller: "projectsListController",
		resolve: {
			projects: function (projectService) {
				return projectService.getAllAsync(true)
				.then(function(projects) {
					if (projects && Array.isArray(projects))
						return projects;

					return [];
				})
			}
		}
	});

	$stateProvider.state("project-main", {
		url: "/projects/{projectId}",
		template: require('./views/projectMain.html'),
		controller: "projectMainController",

		resolve: {
			project: function (projectService, $stateParams) {
				return projectService.getById($stateParams.projectId);
			},
		}
	});

	$stateProvider.state("project-settings", {
		parent: 'project',
		url: "/settings",
		template: require('./views/projectSettings.html'),
		controller: "",

		resolve: {

		}
	});

	$stateProvider.state("project-local-inspection", {
		parent: 'project',
		url: "/localInspection",
		template: require('./views/projectLocalInspection.html'),
		controller: "",

		resolve: {

		}
	});

	$stateProvider.state("project-building-details", {
		parent: 'project',
		url: "/buildingDetails",
		template: require('./views/projectBuildingDetails.html'),
		controller: "",

		resolve: {

		}
	});

	$stateProvider.state("project-building-construction", {
		parent: 'project',
		url: "/buildingConstruction",
		template: require('./views/projectBuildingConstruction.html'),
		controller: "projectBuildingConstructionController",

		resolve: {

		}
	});

	$stateProvider.state("floor", {
		parent: 'project',
		url: "/buildingConstruction/floor/{floorId}",
		template: require('./views/floor.html'),
		controller: "floorController",

		resolve: {
			floor: function (project, $stateParams, $q) {
				var deferred = $q.defer();
				var targetFloor = null;

				for (var i in project.floors) {
					if (project.floors[i].id == $stateParams['floorId'])
						targetFloor = project.floors[i];
				}

				if (targetFloor == null)
					deferred.reject();
				else
					deferred.resolve(targetFloor);

				return deferred.promise;
			}
		}
	});

	$stateProvider.state("project-general-technical-equipment", {
		parent: 'project',
		url: "/generalTechnicalEquipment",
		template: require('./views/projectGeneralTechnicalEquipment.html'),
		controller: "",

		resolve: {

		}
	});

	$stateProvider.state("project-description-and-partition", {
		parent: 'project',
		url: '/descriptionAndPartition',
		template: require('./views/projectDescriptionAndPartition.html'),
		controller: 'projectDescriptionAndPartitionController',

		resolve: {

		}
	});

	$stateProvider.state("description-unit", {
		parent: 'descriptionUnitBase',
		url: '',
		template: require('./views/descriptionUnit.html'),
		controller: 'descriptionUnitController',
		resolve: {

		}
	});

	$stateProvider.state('description-unit-description', {
		parent: 'descriptionUnitBase',
		url: '/description',
		template: require('./views/descriptionUnitDescription.html'),
		controller: 'descriptionUnitDescriptionController'
	});

	$stateProvider.state('description-unit-general-technical-equipment', {
		parent: 'descriptionUnitBase',
		url: '/generalTechnicalEquipment',
		template: require('./views/descriptionUnitGeneralTechnicalEquipment.html'),
		controller: 'descriptionUnitGeneralTechnicalEquipmentController'
	});

	$stateProvider.state('room', {
		parent: 'descriptionUnitBase',
		url: '/rooms/{roomId}',
		template: require('./views/room.html'),
		controller: 'roomController',
		resolve: {
			room: function ($stateParams, descriptionUnit, $q) {
				var deferred = $q.defer();
				var targetRoom = null;
				angular.forEach(descriptionUnit.rooms, function (room) {
					if (room.id == $stateParams.roomId) {
						targetRoom = room;
					}
				});

				if (targetRoom != null)
					deferred.resolve(targetRoom);
				else
					deferred.reject();

				return deferred.promise;
			}
		}
	});

	$stateProvider.state("project-deprecations", {
		parent: 'project',
		url: "/deprecations",
		template: require("./views/projectDeprecations.html"),
		controller: "",

		resolve: {

		}
	});

	$stateProvider.state("project-additional-buildings", {
		parent: 'project',
		url: "/additionalBuildings",
		template: require("./views/projectAdditionalBuildings.html"),
		controller: "",

		resolve: {

		}
	});

	$stateProvider.state("project-outdoor-facility", {
		parent: 'project',
		url: "/outdoorFacility",
		template: require("./views/projectOutdoorFacility.html"),
		controller: "",

		resolve: {

		}
	});

	$stateProvider.state("project-space", {
		parent: 'project',
		url: "/space",
		template: require("./views/projectSpace.html"),
		controller: "projectSpaceController",

		resolve: {

		}
	});

	$stateProvider.state("project-volume", {
		parent: 'project',
		url: "/volume",
		template: require("./views/projectVolume.html"),
		controller: "projectVolumeController",

		resolve: {

		}
	});
});

