﻿angular.module("services").factory("itemDataServiceProvider", function ($http, $q) {

	var cachedData = require('../data.json');

	var service = {
		getFor: function (path) {
			var mappings = {
				'descriptionAndPartition.descriptionUnit.description.floorings': 'description.floorings',
				'descriptionAndPartition.descriptionUnit.description.panelling': 'description.panelling',
				'descriptionAndPartition.descriptionUnit.description.ceiling': 'description.ceiling',
				'descriptionAndPartition.descriptionUnit.description.sanitation': 'description.sanitation',
				'descriptionAndPartition.descriptionUnit.description.kitchenEquipment': 'description.kitchenEquipment',
				'descriptionAndPartition.descriptionUnit.description.windows': 'description.windows',
				'descriptionAndPartition.descriptionUnit.description.doors': 'description.doors',
				'descriptionAndPartition.descriptionUnit.description.specialFacility': 'description.specialFacility',
				'descriptionUnit.generalTechnicalEquipment.hotWaterInstallations': 'generalTechnicalEquipment.hotWaterInstallations',
				'descriptionUnit.generalTechnicalEquipment.heating': 'generalTechnicalEquipment.heating',
				'room.panelling': 'description.panelling',
				'room.floorings': 'description.floorings',
				'room.ceiling': 'description.ceiling',
				'room.kitchenEquipment': 'description.kitchenEquipment',
				'room.windows': 'description.windows',
				'room.sanitation': 'description.sanitation',
			};
			if (mappings[path])
				path = mappings[path];
			var parts = path.split(".");
			var target = cachedData;
			angular.forEach(parts, function (part) {
				if (target != null)
					target = target[part];
			});
			return target;
		},

		blockContainsPlaceholders: function (block) {
			var regexp = /\$\{(.*?)\}/g;
			return regexp.test(block);
		}
	};

	var load = function() {
		if (cachedData != null)
			return $q.resolve(cachedData);
		return $http.get("data.json")
			.then(function(result) {
				cachedData = result.data;
				return result.data;
			});
	};

	return {
		getService: function () {
			return load()
				.then(function() {
					return service;
				});
		}
	}

	
});