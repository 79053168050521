﻿angular.module("controllers").controller("projectMainController", function ($scope, $state, $controller, $window, project, projectRepository, projectService, ModalService, $rootScope) {
	$scope.project = project;
	$scope.baseUrl = CONFIG["backendUrl"];

	$scope.token = function() {
		console.log($rootScope.apiToken);

		return $rootScope.apiToken;
	}

	$scope.generateAsync = async function ($event) {
		try {
			$event.preventDefault();
			await projectService.syncAsync();
			$window.open($scope.baseUrl + '/projects/' + $scope.project.id + '/build?access_token=' + $scope.apiToken);
		}
		catch (e) {
			console.log("Error on generating project");
			alert("Error on generating the document. Please try again.");
		}
	};

	$scope.deleteProjectAsync = async function () {
		if (!confirm(`Sind Sie sicher, dass Sie das Projekt ${$scope.project.name} löschen möchten?`))
			return;
			
		await projectService.deleteAsync($scope.project)
		$state.go('projects-list');
	};

	$scope.sendProjectByMail = async function () {
		try {
			await projectService.syncAsync();
			await ModalService.showModal({
				template: require('./sendProjectByMailDialog.html'),
				controller: 'sendProjectByMailDialogController',
				inputs: {
					project: $scope.project,
					projectService: projectService
				},
				closeDelay: 500,
				preClose: (modal) => { modal.element.modal('hide'); }
			}).then(function (modal) {
				modal.element.modal();
			});
		}
		catch (e) {
			console.log("Error on generating project", e);
			alert("Error on sending the document. Please try again.");
		}
		
	};
});