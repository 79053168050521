﻿import {FloorDto} from './FloorDto'
import { DescriptionUnitDto} from './DescriptionUnitDto'
import {VolumeUnitDto} from './VolumeUnitDto'
import {SpaceUnitDto} from './SpaceUnitDto'

export function ProjectDto() {

	this.Id = "";
	this.Name = "";
	this.Notes = "";
	this.CreatedAt = new Date();
	this.UpdatedAt = new Date();

	this.LocalInspection = {
		Appointments: "",
		Participants: "",
		DataBasis: "",
		PermissionToTakePictures: "",
		RightsBasedOnCadastre: ""
	};

	this.BuildingDetails = {
		TypeOfBuilding: "",
		InheritedWaste: "",
		BuildYear: "",
		Modernization: "",
		EnergyEfficiency: "",
		ExternalView: "",
		Plinth: "",
		OptionsOfExtensions: ""
	};
	
	this.Floors = [];

	this.BuildingConstruction = {
		Foundation: '',
		TypeOfConstruction: '',
		InsideWalls: '',
		OuterWalls: '',
		Staircases: {
			GeneralImpression: '',
			StoreyStaircases: ''
		},
		DoorOfHouseEntrance: '',
		DoorOfSideEntrance: '',
		RoofConstruction: {
			PossibilityToExamine: '',
			Construction: '',
			Shape: '',
			Covering: '',
			InsulationTopmostCeiling: '',
			InsulationBetweenRafters: '',
			InsulationBetweenRaftersLivingArea: '',
			Damage: ''
		}
	};
	
	this.GeneralTechnicalEquipment = {
		Heating: '',
		HotWaterInstallations: '',
		SewageInstallations: '',
		ElectricalInstallation: '',
		SeparatedCounters: '',
		Ventilation: '',
		WaterInstallations: ''	
	};
	
	this.DescriptionAndPartition = {
		SpecialFacility: '',
		Units: []	
	};

	this.Deprecation = {
		StructuralDamageAndConstructionDefects: '',
		Groundplan: '',
		EconomicalDeprecation: '',
		SpecialBuildingComponents: '',
		TanningAndExposureToLight: '',
		GeneralAssessment: ''
	};

	this.AdditionalBuildings = '';

	this.OutdoorFacility = '';
	
	this.SpaceCalculation = {
		CalculationBase: '',
		SpaceUnits: []
	};
	
	this.VolumeCalculation = {
		CalculationBase: '',
		VolumeUnits: []
	};
}

ProjectDto.getTyping = function () {
    return {
        CreatedAt: {
            _type: Date
        },

        UpdatedAt: {
            _type: Date
        },

        Floors: {
            _isArray: true,
            _type: FloorDto
        },

        DescriptionAndPartition: {
            Units: {
                _isArray: true,
                _type: DescriptionUnitDto
            }
        },

        SpaceCalculation: {
            SpaceUnits: {
                _isArray: true,
                _type: SpaceUnitDto
            }
        },

        VolumeCalculation: {
            VolumeUnits: {
                _type: VolumeUnitDto,
                _isArray: true
            }
        }
    };
};